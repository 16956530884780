.twenty-forty-eight-grid {
  display: inline-table;
  width: 247px;
  height: 238px;
  border-radius: 10px;
  position: absolute;
  left: 36rem;
  top: 11rem;
  box-shadow:
    1.9px 2.6px 9.6px rgb(0 0 0 / 7%),
    4.6px 6.3px 18.2px rgb(0 0 0 / 10%),
    8.6px 11.8px 25.8px rgb(0 0 0 / 12%),
    15.4px 21px 32.8px rgb(0 0 0 / 14%),
    28.8px 39.3px 41.4px rgb(0 0 0 / 17%),
    69px 94px 80px rgb(0 0 0 / 24%);
}

.twenty-forty-eight-row {
  display: block;
  width: 365px;
  height: 100px;
  margin-bottom: -5px;
  margin-left: 3px;
}

.cell {
  display: block;
  width: 85px;
  height: 86px;
  margin-right: 6px;
  float: left;
  /* background: rgba(238, 228, 218, 0.35); */
  background-color: black;
  border-radius: 2px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.cell--2 {
  background-image: url('../GameImages/two.gif');
  color: rgba(0, 0, 0, 0);
}

.cell--4 {
  color: rgba(0, 0, 0, 0);
  background-image: url('../GameImages/four.gif');
}

.cell--8 {
  color: rgba(0, 0, 0, 0);
  background-image: url('../GameImages/eight.gif');
}

.cell--16 {
  color: rgba(0, 0, 0, 0);
  background-image: url('../GameImages/sixteen.gif');
}

.cell--32 {
  color: rgba(0, 0, 0, 0);
  background-image: url('../GameImages/threeTwo.gif');
}

.cell--64 {
  color: rgba(0, 0, 0, 0);
  background-image: url('../GameImages/sixFour.gif');
}

.cell--128 {
  color: rgba(0, 0, 0, 0);
  background-image: url('../GameImages/oneTwo.gif');
}

.cell--256 {
  color: rgba(0, 0, 0, 0);
  background-image: url('../GameImages/twoFive.gif');
}

.cell--512 {
  color: rgba(0, 0, 0, 0);
  background-image: url('../GameImages/fiveOne.gif');
}

.cell--1024 {
  color: rgba(0, 0, 0, 0);
  background-image: url('../GameImages/oneZero.gif');
}

.cell--2048 {
  color: rgba(0, 0, 0, 0);
  background-image: url('../GameImages/twoZero.gif');
}

.cell__label {
  display: block;
  position: relative;
  top: 90%;
  margin-top: -50%;
}

/* Media Queries */

@media (max-width: 1352px) {
  .twenty-forty-eight-grid {
    left: 29rem;
  }
}

@media (max-width: 988px) {
  .twenty-forty-eight-grid {
    left: 11rem;
  }
}

@media (max-width: 805px) {
  .twenty-forty-eight-grid {
    left: 6rem;
  }
}

@media (max-width: 593px) {
  .twenty-forty-eight-grid {
    left: 8rem;
  }
}

@media (max-width: 494px) {
  .twenty-forty-eight-grid {
    left: 4rem;
  }
}

@media (max-width: 424px) {
  .twenty-forty-eight-grid {
    left: 4rem;
    top: 11rem;
  }

  .twenty-forty-eight-row {
    width: 248px;
    height: 65px;
  }

  .cell {
    width: 56px;
    height: 50px;
  }

  .cell--2 {
    background-image: url('../GameImages/2.gif');
  }

  .cell--4 {
    color: rgba(0, 0, 0, 0);
    background-image: url('../GameImages/4.gif');
  }

  .cell--8 {
    color: rgba(0, 0, 0, 0);
    background-image: url('../GameImages/8.gif');
  }

  .cell--16 {
    color: rgba(0, 0, 0, 0);
    background-image: url('../GameImages/16.gif');
  }

  .cell--32 {
    color: rgba(0, 0, 0, 0);
    background-image: url('../GameImages/32.gif');
  }

  .cell--64 {
    color: rgba(0, 0, 0, 0);
    background-image: url('../GameImages/64.gif');
  }

  .cell--128 {
    color: rgba(0, 0, 0, 0);
    background-image: url('../GameImages/128.gif');
  }

  .cell--256 {
    color: rgba(0, 0, 0, 0);
    background-image: url('../GameImages/256.gif');
  }

  .cell--512 {
    color: rgba(0, 0, 0, 0);
    background-image: url('../GameImages/512.gif');
  }

  .cell--1024 {
    color: rgba(0, 0, 0, 0);
    background-image: url('../GameImages/64.gif');
  }

  .cell--2048 {
    color: rgba(0, 0, 0, 0);
    background-image: url('../GameImages/64.gif');
  }
}

@media (max-width: 354px) {
  .twenty-forty-eight-grid {
    left: 3rem;
    top: 16rem;
  }
}
