.typing-test {
  text-align: center;
  margin: 20px;
}

.text-display {
  font-size: 24px;
  margin: 20px 0;
  line-height: 1.5;
}
.correct {
  color: green;
}

.incorrect {
  color: red;
}

.statistics {
  margin-top: 20px;
  font-size: 18px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  width: 480px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* enter stlyes here */
.typing__speed__test__result__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  outline: none;
  text-align: 'center';
}

.MuiBackdrop-root {
  backdrop-filter: blur(8px);
}
