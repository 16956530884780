/*----------------------------Category Modal Container----------------------------*/
.dad-jokes-play .category-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dad-jokes-play .category-modal-container.hidden {
  display: none;
}

/*----------------------------Category Modal----------------------------*/
.dad-jokes-play .category-modal {
  width: 19rem;
  background-color: #0d0d0d;
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
}

/*----------------------------Single Category----------------------------*/
.dad-jokes-play .single-category {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid #eee;
  padding: 1rem;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
}
