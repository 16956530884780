.why-typescript h1 {
  font-size: 1.6rem;
  font-weight: bold;
}

.ol-cards,
.ol-cards * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wizard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.wizard-container--core {
  flex: 1;
}

.wizard-container--buttons {
  padding: 1rem;
  height: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wizard-container--note {
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ol-cards {
  --flapWidth: 2rem;
  --flapHeigth: 1rem;
  --iconSize: 3rem;
  --numberSize: 3rem;
  --colGapSize: 2rem;
  width: min(100%, 40rem);
  margin-inline: auto;
  display: grid;
  gap: 2rem;
  padding-inline-start: var(--flapWidth);
  font-family: sans-serif;
  color: #222;
  counter-reset: ol-cards-count;
  list-style: none;
}
.ol-cards li {
  display: grid;
  grid-template-areas: 'icon descr nr';
  gap: 0 var(--colGapSize);
  align-items: center;
  padding: var(--colGapSize) var(--flapWidth) var(--colGapSize) 0;
  border-radius: 1rem 5rem 5rem 1rem;
  background-image: linear-gradient(to bottom right, #e9eaec, #ffffff);
  counter-increment: ol-cards-count;
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
  box-shadow:
    inset 2px 2px 2px white,
    inset -1px -1px 1px rgba(0, 0, 0, 0.25);
}

.ol-cards li > .icon {
  grid-area: icon;
  background: #ee5830;
  color: white;
  font-size: var(--iconSize);
  width: calc(2 * var(--flapWidth) + var(--iconSize));
  padding-block: 1rem;
  border-radius: 0 5rem 5rem 0;
  margin-inline-start: calc(-1 * var(--flapWidth));
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  display: grid;
  place-items: center;
}
.ol-cards li > .icon::before {
  content: '';
  position: absolute;
  width: var(--flapWidth);

  height: calc(100% + calc(var(--flapHeigth) * 2));
  left: 0;
  top: calc(var(--flapHeigth) * -1);
  clip-path: polygon(0 var(--flapHeigth), 100% 0, 100% 100%, 0 calc(100% - var(--flapHeigth)));
  background-color: #ee5830;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2));
  z-index: -1;
}

.ol-cards li > .descr {
  grid-area: descr;
  width: 100%;
}
.ol-cards li::after {
  grid-area: nr;
  content: counter(ol-cards-count, decimal-leading-zero);
  color: #ee5830;
  font-size: var(--numberSize);
  font-weight: 700;
}
@media (max-width: 40rem) {
  .ol-cards {
    --flapWidth: 1rem;
    --flapHeigth: 0.5rem;
    --iconSize: 2rem;
    --numberSize: 2rem;
    --colGapSize: 1rem;
  }
}

.s-button span {
  background: #fff;
  display: block;
  padding: 5px 15px;
  border-radius: 5px;
  border: 2px solid #000;
}
.s-button:hover {
  box-shadow:
    0 2px 0 #000,
    0 2px 0px 2px #000;
}
.s-button:active {
  top: 4px;
  padding-bottom: 0px;
  box-shadow: 0 1px 0 #000;
}
.s-button {
  position: relative;
  float: left;
  font:
    normal 14px/15px 'Patrick Hand',
    sans-serif;
  margin-right: 10px;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
  padding-bottom: 3px;
  border-radius: 5px;
  box-shadow: 0 2px 0 #000;
  transition:
    padding 0.1s,
    box-shadow 0.1s,
    top 0.1s;
  background-image: url('data:image/gif;base64,R0lGODlhBAAEAIABAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjEgNjQuMTQwOTQ5LCAyMDEwLzEyLzA3LTEwOjU3OjAxICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1LjEgV2luZG93cyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo5NUY1OENCRDdDMDYxMUUyOTEzMEE1MEM5QzM0NDVBMyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo5NUY1OENCRTdDMDYxMUUyOTEzMEE1MEM5QzM0NDVBMyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjk1RjU4Q0JCN0MwNjExRTI5MTMwQTUwQzlDMzQ0NUEzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjk1RjU4Q0JDN0MwNjExRTI5MTMwQTUwQzlDMzQ0NUEzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAQAAAQAsAAAAAAQABAAAAgYEEpdoeQUAOw==');
}

.s-button:disabled {
  cursor: not-allowed;
}

.s-button:disabled span {
  background-color: #ababab;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-container h1 {
  color: #ee5830;
}
