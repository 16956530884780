.quizeo .score-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quizeo .score-section {
  display: flex;
  position: absolute;
  top: 25%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 24rem;
  height: 16rem;
  border-radius: 1.5rem;
}

.quizeo .score-close {
  border: none;
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: black;
  background-color: white;
}

.quizeo .score-section h1 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
}

.quizeo .play-button {
  color: white;
  border: none;
  padding: 0.75rem;
  background-color: rgb(110, 216, 216);
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  margin-left: 5rem;
  margin-right: 5rem;
  border-radius: 1.5rem;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
