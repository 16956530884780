.navbar-play-contain {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 20rem));
}

.navbar-side-card {
  position: relative;
  top: -18rem;
  left: 8rem;
  display: flex;
  height: 330px;
  width: 220px;
  background-color: #191c29;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  text-align: center;
  border: 2px solid rgb(88 199 250);
  overflow: hidden;
  transition: 0.6s;
  box-shadow: 0px 0px 100px rgba(88, 199, 250, 0.15);
}

.nav-card-images {
  position: relative;
  font-size: 33px;
  font-family: roboto;
  color: rgb(88 199 250);
  font-weight: 100;
  letter-spacing: 1px;
  transition: 0.7s;
  transform: translateY(110px);
  top: -23px;
  border-radius: 15px;
}

.navbar-side-card:hover .nav-card-images {
  transform: translateY(-150px);
  opacity: 0;
}

.nav-side-card-description {
  color: #dbf9ff;
  font-family: roboto;
  font-weight: 100;
  font-size: 14px;
  width: 170px;
  line-height: 25px;
  transform: translateY(200px);
  transition: 0.7s;
  opacity: 0;
}

.nav-side-card-heading {
  font-size: 38px;
  font-family: roboto;
  color: rgb(88 199 250);
  font-weight: 400;
  margin: 0;
  padding: 0;
  transform: translateY(200px);
  opacity: 1;
  transition: 0.6s;
  opacity: 0;
}

.nav-card-button {
  transform: translatey(200px);
  transition: 0.88s;
  opacity: 0;
  width: 110px;
  height: 38px;
  border-radius: 40px;
  font-size: 12px;
  font-family: roboto;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1px;
  color: white;
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
  border: none;
  cursor: pointer;
}

.navbar-side-card:hover .nav-side-card-hover {
  display: block;
}

.navbar-side-card:hover .nav-side-card-heading {
  transform: translatey(-170px);
  opacity: 1;
}

.navbar-side-card:hover .nav-side-card-description {
  transform: translatey(-150px);
  opacity: 1;
}

.navbar-side-card:hover .nav-card-button {
  transform: translatey(-100px);
  opacity: 1;
}

.navbar-side-card:hover {
  transform: scale(110%);
  box-shadow: 0px 0px 100px rgb(88 199 250);
}

.nav-card-button:active {
  color: #0beef9;
}

h1.side-animated-heading {
  color: #333;
  font-family: tahoma;
  font-size: 3rem;
  font-weight: 100;
  line-height: 1.5;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 610px;
}

h1.side-animated-heading span {
  font-size: 40px;
  margin-left: 2px;
}

.nav-side-message-box {
  background-color: #76cfe5;
  color: #333;
  display: block;
  font-weight: 900;
  overflow: hidden;
  position: absolute;
  padding-left: 0.5rem;
  top: 0.2rem;
  left: 220px;
  animation: openclose 5s ease-in-out infinite;
}

.side-box-animated-word1,
.side-box-animated-word2,
.side-box-animated-word3 {
  font-family: tahoma;
}

@keyframes openclose {
  0% {
    top: 0.2rem;
    width: 0;
  }

  5% {
    width: 0;
  }

  15% {
    width: 150px;
  }

  30% {
    top: 0.2rem;
    width: 152px;
  }

  33% {
    top: 0.2rem;
    width: 0;
  }

  35% {
    top: 0.2rem;
    width: 0;
  }

  38% {
    top: -4.5rem;
  }

  48% {
    top: -4.5rem;
    width: 250px;
  }

  62% {
    top: -4.5rem;
    width: 250px;
  }

  66% {
    top: -4.5rem;
    width: 0;
    text-indent: 0;
  }

  71% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }

  86% {
    top: -9rem;
    width: 450px;
  }

  95% {
    top: -9rem;
    width: 450px;
  }

  98% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }

  100% {
    top: 0;
    width: 0;
    text-indent: 0;
  }
}

.side-box-contentiner {
  background-color: #485461;
  background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
  color: white;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  transition: all 0.3s ease-in-out;
  padding: 10px;
  padding-bottom: 0;
  margin-top: 40px;
  margin-bottom: 10px;
  height: 200px;
}

p.side-box-contentiner-sub {
  padding: 2rem;
  text-align: center;
}

button#side-box-neon {
  font-size: 29px;
}

#side-box-neon:hover span {
  animation: flicker 1s linear forwards;
}

#side-box-neon:hover #a {
  animation-delay: 0.2s;
}

#side-box-neon:hover #c {
  animation-delay: 0.5s;
}

#side-box-neon:hover #t {
  animation-delay: 0.6s;
}

#side-box-neon:hover #pl {
  animation-delay: 0.8s;
}

#side-box-neon:hover #a2 {
  animation-delay: 0.9s;
}

#side-box-neon:hover #y {
  animation-delay: 0.9s;
}

@keyframes flicker {
  0% {
    color: #333;
  }

  5%,
  15%,
  25%,
  30%,
  100% {
    color: #fff;
    text-shadow: 0px 0px 25px cyan;
  }

  10%,
  20% {
    color: #333;
    text-shadow: none;
  }
}

#side-box-neon:focus {
  outline: none;
}

.side-box-twt-icon {
  width: 70px;
  cursor: pointer;
  color: #19d2ef;
  font-size: 20px;
}

.side-box-twt-icon:hover {
  font-size: 26px;
  transition: 0.3s ease-in-out;
}

/* Media Query */
@media (max-width: 990px) {
  h1.side-animated-heading span {
    font-size: 29px;
  }

  .nav-side-message-box {
    left: 168px;
  }

  h1.side-animated-heading {
    font-size: 2rem;
    width: 478px;
  }

  @keyframes openclose {
    0% {
      top: 0.2rem;
      width: 0;
    }

    5% {
      width: 0;
    }

    15% {
      width: 150px;
    }

    30% {
      top: 0.2rem;
      width: 152px;
    }

    33% {
      top: 0.2rem;
      width: 0;
    }

    35% {
      top: 0.2rem;
      width: 0;
    }

    38% {
      top: -6.5rem;
    }

    48% {
      top: -2.9rem;
      width: 200px;
    }

    62% {
      top: -2.9rem;
      width: 200px;
    }

    66% {
      top: -2.9rem;
      width: 0;
      text-indent: 0;
    }

    71% {
      top: -6.1rem;
      width: 0;
      text-indent: 5px;
    }

    86% {
      top: -6.1rem;
      width: 262px;
    }

    95% {
      top: -6.1rem;
      width: 262px;
    }

    98% {
      top: -6.1rem;
      width: 0;
      text-indent: 5px;
    }

    100% {
      top: 0;
      width: 0;
      text-indent: 0;
    }
  }
}

@media (max-width: 790px) {
  h1.side-animated-heading span {
    font-size: 19px;
  }

  .nav-side-message-box {
    left: 109px;
  }

  h1.side-animated-heading {
    font-size: 22px;
    width: 291px;
  }

  @keyframes openclose {
    0% {
      top: 0.2rem;
      width: 0;
    }

    5% {
      width: 0;
    }

    15% {
      width: 90px;
    }

    30% {
      top: 0.2rem;
      width: 92px;
    }

    33% {
      top: 0.2rem;
      width: 0;
    }

    35% {
      top: 0.2rem;
      width: 0;
    }

    38% {
      top: -6.5rem;
    }

    48% {
      top: -1.9rem;
      width: 130px;
    }

    62% {
      top: -1.9rem;
      width: 130px;
    }

    66% {
      top: -1.9rem;
      width: 0;
      text-indent: 0;
    }

    71% {
      top: -4.1rem;
      width: 0;
      text-indent: 5px;
    }

    86% {
      top: -4.1rem;
      width: 200px;
    }

    95% {
      top: -4.1rem;
      width: 200px;
    }

    98% {
      top: -4.1rem;
      width: 0;
      text-indent: 5px;
    }

    100% {
      top: 0;
      width: 0;
      text-indent: 0;
    }
  }
}

@media (max-width: 713px) {
  .navbar-side-card {
    height: 220px;
    width: 180px;
    left: 4rem;
    top: -17rem;
  }

  .navbar-side-card:hover .nav-side-card-heading {
    transform: translatey(-101px);
    opacity: 1;
  }

  .navbar-side-card:hover .nav-side-card-description {
    transform: translatey(-105px);
    opacity: 1;
  }

  .nav-side-card-description {
    font-size: 11px;
  }
}

@media (max-width: 620px) {
  .side-box-contentiner {
    margin-top: 30px;
    height: 137px;
    width: 243px;
  }

  p.side-box-contentiner-sub {
    padding: 0rem;
    text-align: center;
    font-size: 12px;
  }

  .side-box-twt-icon {
    width: 26px;
  }
}

@media (max-width: 569px) {
  .w-64.duration-500.h-screen.p-5.pt-8.bg-slate-900.relative.md\:w-30,
  .w-20.duration-500.h-screen.p-5.pt-8.bg-slate-900.relative.md\:w-30 {
    width: 5rem;
  }

  img.absolute.cursor-pointer.rounded-full.-right-3.top-9.w-7.border-2.bg-cyan-500.false,
  img.absolute.cursor-pointer.rounded-full.-right-3.top-9.w-7.border-2.bg-cyan-500.rotate-180 {
    visibility: hidden;
  }

  h1.side-animated-heading.text-white.origin-left.font-medium.text-xl.duration-300.false {
    visibility: hidden;
  }

  h1.text-white.origin-left.font-medium.text-xl.duration-300.false {
    visibility: hidden;
  }

  span.false.origin-left.duration-200 {
    visibility: hidden;
  }

  .side-box-contentiner {
    position: relative;
    left: -6rem;
  }

  h1.side-animated-heading {
    left: -7rem;
  }

  .navbar-side-card {
    top: -10rem;
  }
}

@media (max-width: 501px) {
  .w-64.duration-500.h-screen.p-5.pt-8.bg-slate-900.relative.md\:w-30 {
    width: 5rem;
  }

  .navbar-side-card {
    height: 195px;
    width: 180px;
    left: -1rem;
    top: -11rem;
  }
}

@media (max-width: 455px) {
  .w-64.duration-500.h-screen.p-5.pt-8.bg-slate-900.relative.md\:w-30,
  .w-20.duration-500.h-screen.p-5.pt-8.bg-slate-900.relative.md\:w-30 {
    width: 5rem;
  }

  img.absolute.cursor-pointer.rounded-full.-right-3.top-9.w-7.border-2.bg-cyan-500.false,
  img.absolute.cursor-pointer.rounded-full.-right-3.top-9.w-7.border-2.bg-cyan-500.rotate-180 {
    visibility: hidden;
  }

  h1.heading.text-white.origin-left.font-medium.text-xl.duration-300.false {
    visibility: hidden;
  }

  span.false.origin-left.duration-200 {
    visibility: hidden;
  }
}

@media (max-width: 418px) {
  .side-box-contentiner {
    padding: 3rem 1rem;
    height: 211px;
    width: 174px;
    left: -3rem;
  }

  .nav-side-message-box {
    left: 60px;
  }

  h1.side-animated-heading span {
    visibility: hidden;
  }
}

@media (max-width: 341px) {
  .nav-side-card-description {
    font-size: 10px;
    text-align: center;
  }

  .navbar-side-card {
    width: 158px;
    left: -3rem;
    top: -16rem;
  }

  .nav-side-card-heading {
    font-size: 28px;
  }
}

@media (max-width: 292px) {
  .navbar-side-card {
    left: -1.5rem;
  }
}
