.play-details-body > * {
  box-sizing: border-box;
}

.play-details-body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.airbnb-nav {
  height: 70px;
  display: flex;
  padding: 20px 36px;
  box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
}

.bold {
  font-weight: bold;
}

.gray {
  color: #918e9b;
}

.airbnb-nav--logo {
  max-width: 100px;
}

.airbnb-hero {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.hero--photo {
  max-width: 400px;
  align-self: center;
}

.hero--header {
  margin-bottom: 16px;
}

.hero--text {
  margin-top: 0;
}

.cards-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
}

.card {
  width: 175px;
  font-size: 12px;
  flex: 0 0 auto; /* ADD */
  display: flex; /* ADD */
  flex-direction: column; /* ADD */
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card--image {
  width: 100%;
  border-radius: 9px;
  margin-bottom: 9px;
}

/* ADD */
.card--title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.card--stats {
  display: flex;
  align-items: center;
}

.card--star {
  height: 14px;
}

/* ADD */
.card--price {
  margin-top: auto;
}

.card--badge {
  position: absolute;
  top: 6px;
  left: 6px;
  background-color: white;
  padding: 5px 7px;
  border-radius: 2px;
  font-weight: bold;
}
