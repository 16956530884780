.text-avatar_card {
  border: 1px solid #dcdce0;
  padding: 1rem;
  max-width: 200px;
  min-width: 200px;
  height: 150px;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  & .text-avatar_username {
    margin-top: 2rem;
    font-weight: bold;
    color: black;
  }
}
