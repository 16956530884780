.page-404 {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 120px);
}

.page-404-image {
  max-width: 100%;
  height: auto;
}

.under-development {
  max-width: 600px;
}

.page-404-lead {
  margin: 0;
  font-family: var(--ff-accent);
  font-size: var(--fs-xl);
  text-align: center;
}

@media screen and (max-width: 768px) {
  .page-404-lead {
    line-height: var(--lh-rg);
    margin-bottom: 1rem;
  }
  .under-development {
    max-width: calc(100vw - 100px);
  }
}

.page-404-desc {
  margin-top: 0;
  font-size: var(--fs-md);
  text-align: center;
}

.page-404-link {
  position: relative;
  color: var(--color-neutral-80);
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  font-weight: var(--fw-semibold);
}
.page-404-link:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  height: 2px;
  background-color: var(--color-brand-primary);
  transition: all 0.1s ease-in-out;
}

.page-404-link:hover,
.page-404-link:focus {
  color: var(--color-neutral-90);
}

.page-404-link:hover:after,
.page-404-link:focus:after {
  height: 5px;
  bottom: 0;
  /* background-color: var(--color-neutral-90); */
}

.link {
  text-decoration: underline;
}
