.hackathon-banner-image {
  width: 100%;
  height: 300px;
  margin: 0 auto;
  object-fit: contain;
  object-position: 100% center;
}
@media screen and (min-width: 992px) {
  .hackathon-banner-image {
    width: 100%;
    height: 360px;
    object-position: 90% center;
  }
}

.app-home-body .body-c2a.body-c2a-hackathon {
  position: relative;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  margin: 1rem auto;
  justify-content: center;
}

@media screen and (min-width: 992px) {
  .app-home-body .body-c2a.body-c2a-hackathon {
    justify-content: start;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 576px) {
  .app-home-body .body-c2a.body-c2a-hackathon {
    justify-content: center;
    flex-wrap: wrap;
  }
}
