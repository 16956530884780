.tenzies_main {
  width: 45vw;
  height: 100%;
  background-color: #f5f5f5;
  margin: 0px auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tenzies_dice_container {
  /* width: 100%; */

  display: grid;
  grid-template: auto auto / repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 35px;
}
.tenzies_dice {
  height: 60px;
  width: 60px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
}
.tenzies_die_num {
  font-size: 2rem;
}
.tenzies_roll_btn {
  height: 50px;
  width: 150px;
  border: none;
  border-radius: 6px;
  background-color: #5035ff;
  color: white;
  font-size: 1.2rem;
  font-family: 'Karla', sans-serif;
  cursor: pointer;
}
.tenzies_roll_btn:active {
  box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
}
.tenzies_title {
  font-size: 40px;
  margin-bottom: 10px;
}

.tenzies_instructions {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin-top: 0;
  text-align: center;
  margin-bottom: 30px;
}
