.suri-calc-container {
  /* background-color: #dfe9f5; */
  /* min-height: 100vh; */
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suri-calc-main {
  border: 0px;
  box-shadow: 0px 0px 3px 0.5px #c4e0e5;
  border-radius: 10px;
  max-width: 400px;
}

@media (max-width: 600px) {
  .suri-calc-main {
    width: 100%;
    height: 100%;
  }
}
