@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

.password-generator {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
}

.password-generator .main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.password-generator .title {
  font-size: 3.5em;
  font-weight: 900;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  margin-bottom: 0.5em;
}

.password-generator .main .inputfield {
  outline: 1px solid #000;
  padding: 1em;
  border-radius: 1.5em;
}

.password-generator .main .inputfield .text {
  border: none;
  outline: none;
  font-size: 1.2em;
  margin: 0.1em 0.5em;
  font-weight: 600;
  color: rgb(104, 104, 104);
}

.password-generator .main .inputfield .copy {
  background-color: #18c0f4;
  outline: none;
  border: none;
  font-size: 1em;
  font-weight: 900;
  color: #fff;
  padding: 0.6em 1em;
  border-radius: 0.7em;
  text-transform: uppercase;
}

.password-generator .main .block {
  display: flex;
  gap: 1em;
}

.password-generator .main .block .select {
  margin: 1.5em 1em 0 1em;
  display: flex;
  border-radius: 10em;
  padding: 0.1em 1em;
  align-items: center;
  gap: 1em;
  width: 14em;
  background-color: #18c0f4;
  color: #fff;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
}

.password-generator .main .block .select h3 {
  margin: 0.5em auto;
  justify-self: start;
}

.password-generator .main .block .select .bigCircle {
  background-color: #ffffff;
  height: 2em;
  width: 2em;
  border-radius: 50%;
  justify-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.password-generator .main .block .select .smallCircle {
  background-color: #18c0f4;
  height: 1em;
  width: 1em;
  border-radius: 50%;
}

.password-generator .main .block .flexlength {
  display: flex;
  outline: 0.15em solid #000;
  width: 30em;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 5em;
  margin-top: 2em;
  border-radius: 10em;
  padding-top: 0em;
}

.password-generator .main .block .flexlength .select {
  width: 5em;
  text-align: center;
  outline: none;
  border: 2px solid #000;
  font-size: 1.2em;
  border-radius: 0.2em;
  background-color: transparent;
  color: #000;
  margin-top: 0;
}

.password-generator .main .block .flexlength h3 {
  font-weight: 900;
  margin: 0.6em;
}

.password-generator .main .block .sub {
  margin: 1.5em;
}

.password-generator .main .block .sub input {
  color: #fff;
  background-color: #016daf;
  cursor: pointer;
  font-size: 1.4em;
  width: 22em;
  font-weight: 900;
  outline: none;
  border: none;
  border-radius: 5em;
  padding: 0.7em;
  text-transform: uppercase;
}

input#lowercaseToggle:not([type='checkbox']:checked) ~ .lowercase .bigCircle .smallCircle,
input#uppercaseToggle:not([type='checkbox']:checked) ~ .uppercase .bigCircle .smallCircle,
input#numberToggle:not([type='checkbox']:checked) ~ .number .bigCircle .smallCircle,
input#specialCharToggle:not([type='checkbox']:checked) ~ .specialchar .bigCircle .smallCircle {
  display: none;
}

input#lowercaseToggle:not([type='checkbox']:checked) ~ .lowercase .bigCircle,
input#uppercaseToggle:not([type='checkbox']:checked) ~ .uppercase .bigCircle,
input#numberToggle:not([type='checkbox']:checked) ~ .number .bigCircle,
input#specialCharToggle:not([type='checkbox']:checked) ~ .specialchar .bigCircle {
  outline: 1px solid #000;
  background-color: transparent;
}

input#lowercaseToggle:not([type='checkbox']:checked) ~ .lowercase,
input#uppercaseToggle:not([type='checkbox']:checked) ~ .uppercase,
input#numberToggle:not([type='checkbox']:checked) ~ .number,
input#specialCharToggle:not([type='checkbox']:checked) ~ .specialchar {
  outline: 1px solid #000;
  background-color: transparent;
  color: #000;
}

.error {
  color: red;
}

@media (max-width: 628px) {
  .password-generator .title {
    font-size: 2.7em;
  }

  .password-generator .main .inputfield .text {
    width: 16em;
  }

  .password-generator .main .block {
    gap: 0em;
  }

  .password-generator .main .block .select {
    margin: 1.2em 0.3em 0 0.3em;
    width: 13em;
  }

  .password-generator .main .block .flexlength {
    width: 26em;
  }
  .password-generator .main .block .sub input {
    width: 19em;
  }
}

@media (max-width: 500px) {
  .password-generator .title {
    font-size: 1.7em;
  }

  .password-generator .main .inputfield .text {
    width: 12em;
    font-size: 0.9em;
  }
  .password-generator .main .block .select h3 {
    font-size: 0.8em;
  }
  .password-generator .main .block {
    gap: 0em;
  }
  .password-generator .main .block .select .bigCircle {
    height: 1em;
    width: 1em;
  }

  .password-generator .main .block .select .smallCircle {
    height: 0.5em;
    width: 0.5em;
  }

  .password-generator .main .block .select {
    margin: 1.2em 0.3em 0 0.3em;
    width: 9em;
    gap: 0em;
  }

  .password-generator .main .block .flexlength {
    margin-top: 1em;
    width: 18em;
  }
  .password-generator .main .block .sub input {
    font-size: 1em;
    width: 19em;
  }
  .password-generator .main .block .flexlength h3 {
    font-size: 0.9em;
  }

  .password-generator .main .block .flexlength .select {
    font-size: 0.9em;
  }
}
