@import './variables';

.fun-quiz-main {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .main-child {
    width: 500px;

    .quiz-summary div:nth-child(1) {
      font-size: 1.1rem;
      line-height: 1;
      margin: 5px 0;
      display: flex;
      align-items: center;
      gap: 10px;

      h4:nth-of-type(1) {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;

        span:nth-of-type(1) {
          font-size: 4rem;
          line-height: 1;
          padding: 0;
          margin: 0;
          font-weight: bold;
        }
      }

      h4:nth-of-type(2) {
        margin: 0;
        font-size: 1.5rem;
        line-height: 1;
        color: $quiz-app-raw-red;
      }

      @media screen and (max-width: 600px) {
        font-size: 1rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .quiz-summary div:nth-child(2) {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 1.1rem;

      span:nth-of-type(1) {
        font-size: 4rem;
        font-weight: bold;
        color: $quiz-app-raw-red;
        line-height: 1;
      }

      span:nth-of-type(2) {
        font-weight: bold;
      }
    }

    h1 {
      margin-top: 20px;
      display: inline-block;
      margin-bottom: 40px;

      &::after {
        content: '';
        display: block;
        width: 100%;
        margin-top: 5px;
        border: 2px solid $quiz-app-light-red;
        border-radius: 20px;
      }
    }

    p {
      font-size: 1rem;
      font-weight: normal;
      margin-top: -25px;
      margin-bottom: 25px;
      color: $quiz-app-green-color2;
      line-height: 1.5;
    }

    .selectable-options {
      display: flex;
      gap: 10px 15px;
      width: 100%;
      flex-wrap: wrap;

      .single-selection {
        background-color: $quiz-app-light-gray;
        box-shadow: inset 0px 0px 1px 1px rgba(218, 218, 218, 0.4);
        padding: 8px 16px;
        cursor: pointer;
        border-radius: 20px;
        color: $quiz-app-black-color;
        font-weight: bold;

        &:hover {
          background-color: $quiz-app-black-color;
          color: $quiz-app-white-color;
          box-shadow: none;
          transition: 75ms ease-in;
        }
      }

      .active-selected {
        background-color: $quiz-app-black-color;
        color: $quiz-app-white-color;
        box-shadow: none;
      }
    }

    .front-footer {
      margin: 2rem 0;

      button {
        outline: none;
        border: none;
        cursor: pointer;
        background: $quiz-app-black-color;
        width: 100%;
        padding: 12px 0;
        font-size: 1rem;
        color: $quiz-app-white-color;
        border-radius: 40px;
        font-weight: bold;
        box-shadow: 0px 0px 10px $quiz-app-transparent-black;
        font-family: 'Quicksand', sans-serif;

        &:hover {
          background-color: $quiz-app-black-color2;
        }
      }
    }

    .congrats {
      color: $quiz-app-raw-green;
    }
    .cheated {
      color: $quiz-app-raw-red;
    }

    .circle-area {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .circle-correct {
        width: 50px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: $quiz-app-raw-green;
        color: $quiz-app-white-color;
        display: grid;
        place-items: center;
        cursor: pointer;
      }

      .circle-incorrect {
        width: 50px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: $quiz-app-light-red2;
        color: $quiz-app-white-color;
        display: grid;
        place-items: center;
        cursor: pointer;
      }
    }

    .display-question {
      margin: 1.5rem 0;
      width: 100%;
      padding: 12px;
      border-radius: 10px;
      border: 1px solid $quiz-app-border-color;
      color: $quiz-app-white-color;
      position: relative;
    }

    .correct {
      background-color: $quiz-app-raw-green;
    }

    .incorrect {
      background-color: $quiz-app-light-red2;
    }

    .question-number {
      position: absolute;
      top: -10px;
      right: 20px;
      background-color: $quiz-app-black-color;
      padding: 0 10px;
      border-radius: 20px;
      color: $quiz-app-white-color;
    }

    .back {
      outline: none;
      border: none;
      background-color: transparent;
      font-weight: bold;
      font-size: 1rem;
      color: $quiz-app-deep-blue;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 600px) {
    .main-child {
      width: calc(100% - 2rem);
    }
  }
}
