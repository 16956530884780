/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Raleway:wght@900&display=swap'); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Raleway:wght@900&display=swap');

.text-center {
  text-align: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

button,
input,
optgroup,
select,
textarea {
  color: initial;
}

:root {
  --color-brand-primary: #00f2fe;
  --color-brand-primary-rgb: 0, 244, 252;
  --color-brand-primary-alt: #61dafb;
  --color-brand-primary-dark: #00aada;

  /* Neutral */
  --color-neutral-10: #ffffff;
  --color-neutral-20: #f6f6f9;
  --color-neutral-30: #e0e0e6;
  --color-neutral-40: #bdbdbd;
  --color-neutral-50: #7f878a;
  --color-neutral-60: #5c6c70;
  --color-neutral-70: #3e5156;
  --color-neutral-80: #242230;
  --color-neutral-90: #010326;
  --color-neutral-100: #000000;

  --color-neutral-90-rgb: 1, 3, 38;
  --color-neutral-10-rgb: 255, 255, 255;
  --color-neutral-100-rgb: 0, 0, 0;

  /* Font Family */
  --ff-default: Poppins, -apple-system, Roboto, Helvetica Neue, sans-serif;
  --ff-accent: Raleway, Inter, -apple-system, Roboto, Helvetica Neue, sans-serif;

  /* Font Weight */
  --fw-light: 300;
  --fw-regular: 400;
  --fw-semibold: 500;
  --fw-bold: 600;

  /* Font Sizes */
  --fs-xxs: 0.6rem;
  --fs-xs: 0.7rem;
  --fs-sm: 0.8rem;
  --fs-rg: 0.9rem;
  --fs-md: 1.4rem;
  --fs-lg: 2rem;
  --fs-xl: 3rem;
  --fs-xxl: 4rem;

  /* Line Height */
  --lh-xs: 0.8em;
  --lh-sm: 1em;
  --lh-rg: 1.2em;
  --lh-md: 1.8em;
  --lh-lg: 2.4em;
  --lh-xl: 3em;

  /* Responsive Breakpoints */
  --screen-sm-min: 768px;
  --screen-md-min: 992px;
  --screen-md-max: 1024px;
  --screen-lg-min: 1172px;
  --screen-lg-max: 1200px;
  --screen-xl-max: 1400px;

  /* Header */
  --navbar-icon-size-rg: 24px;
  --navbar-icon-size-sm: 16px;

  /* Play */
  --play-thumb-size-w: 260px;
  --play-thumb-size-h: 146px;
  /* For Mobile */
  --play-thumb-size-w-mobile: 308px;
  --play-thumb-size-h-mobile: 194px;

  /* Play level */
  --color-play-level-1: 3, 169, 244;
  --color-play-level-2: 255, 111, 0;
  --color-play-level-3: 229, 57, 53;
}

html,
body {
  height: auto;
  padding: 0;
  margin: 0;
  font-family: var(--ff-default);
  line-height: 1.6;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body * {
  box-sizing: border-box;
  font-family: var(--ff-default);
}

.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

small {
  font-size: var(--fs-sm);
}

@media screen and (max-width: 1200px) {
  :root {
    /* Font Sizes */
    --fs-rg: 0.8rem;
    --fs-md: 1.2rem;
    --fs-lg: 1.4rem;
    --fs-xl: 2rem;
    --fs-xxl: 3.4rem;
  }
}

@media screen and (max-width: 1024px) {
  :root {
    /* Font Sizes */
    --fs-rg: 0.8rem;
    --fs-md: 1.2rem;
    --fs-lg: 1.4rem;
    --fs-xl: 2rem;
    --fs-xxl: 2.8rem;
    --play-thumb-size: 240px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    /* Font Sizes */
    --fs-rg: 0.8rem;
    --fs-md: 1rem;
    --fs-lg: 1.4rem;
    --fs-xl: 1.8rem;
    --fs-xxl: 2rem;

    /* Header */
    --navbar-icon-size-rg: 20px;
    --navbar-icon-size-sm: 14px;
  }
}

@media screen and (max-width: 480px) {
  :root {
    /* Font Sizes */
    --play-thumb-size: 280px;
  }
}

/* App Body */
.app-body {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--color-neutral-20);
}

.app-body-overflow-hidden {
  overflow-y: hidden;
  height: 100%;
}

.section-title {
  margin: 0 0 0.6rem 0;
  font-family: var(--ff-accent);
  font-size: var(--fs-lg);
  font-weight: var(--fw-bold);
  color: var(--color-neutral-90);
}

/* Anchors or Links */
.text-link-default {
  font-weight: var(--fw-semibold);
  color: var(--color-neutral-80);
  text-decoration: underline;
}

.text-link-default:hover,
.text-link-default:focus {
  color: var(--color-brand-primary-alt);
  text-decoration: underline;
}

/* App Footer */
.app-footer {
  padding: 0 12px;
  font-size: var(--fs-xs);
  font-weight: var(--fw-light);
  color: var(--color-neutral-50);
  border-top: solid 1px var(--color-neutral-30);
}

.app-footer.app-footer--home {
  padding: 3rem 2rem 0 2rem;
}

.app-footer-body {
  width: 80%;
  max-width: var(--screen-lg-max);
  margin: 0 auto 1rem auto;
  display: none;
  grid-template-columns: 1fr 1.2fr 0.8fr;
  gap: 4rem;
}

.app-footer--home .app-footer-body {
  display: grid;
}

.app-footer-body .section-title {
  margin: 0 0 0.6rem 0;
  font-family: var(--ff-accent);
  font-size: var(--fs-lg);
  font-weight: var(--fw-bold);
  color: var(--color-neutral-90);
}

.app-footer-body p {
  font-size: var(--fs-rg);
  color: var(--color-neutral-70);
}

.app-footer-body .p-lg {
  margin: 0;
  font-size: var(--fs-md);
  margin-bottom: 1rem;
}

.app-footer-body .social-links {
  margin: 0.6rem 0 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.app-footer-body .social-links .social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 48px;
  background-color: rgba(var(--color-neutral-100-rgb), 0.08);
  transition: all 0.12s ease-in-out;
}

.app-footer-body .social-links .social-link .icon {
  width: 24px;
  height: 24px;
  fill: rgba(var(--color-neutral-100-rgb), 0.6);
  transition: all 0.12s ease-in-out;
}

.app-footer-body .social-links .social-link:hover,
.app-footer-body .social-links .social-link:focus {
  background-color: var(--color-brand-primary);
}

.app-footer-body .social-links .social-link:hover .icon,
.app-footer-body .social-links .social-link:focus .icon {
  fill: var(--color-neutral-100);
}

.app-footer.app-footer--home .separater {
  display: block;
  max-width: var(--screen-lg-max);
  margin: 0 auto;
}

.app-footer .separater {
  display: none;
}

.app-footer .copy {
  margin: 0.4rem 0;
  text-align: center;
}

.app-footer.app-footer--home .copy {
  margin: 1rem 0;
}

.app-footer .copy a {
  color: var(--color-neutral-90);
  text-decoration: underline;
}

.app-footer .copy a:hover,
.app-footer .copy a:focus {
  color: var(--color-brand-primary-alt);
}

@media screen and (max-width: 1176px) {
  .app-footer--home .app-footer-body {
    display: flex;
    flex-wrap: wrap;
  }
  .app-footer--home .app-footer-body .body-primary {
    width: 100%;
  }

  .app-footer--home .app-footer-body .body-secondary,
  .app-footer--home .app-footer-body .body-tertiary {
    width: 40%;
  }
}

@media screen and (max-width: 480px) {
  .app-footer--home .app-footer-body {
    display: flex;
    flex-wrap: wrap;
    gap: 2.6rem;
    width: 100%;
  }

  .app-footer--home .app-footer-body .body-primary,
  .app-footer--home .app-footer-body .body-secondary,
  .app-footer--home .app-footer-body .body-tertiary {
    width: 100%;
  }
}

.app-footer-text {
  color: var(--color-neutral-80);
}

/* Buttons */
.btn-default {
  border: solid 1px var(--color-neutral-60);
  border-radius: 2rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.4rem;
  padding: 0.8rem 1.4rem;
  font-size: var(--fs-rg);
  font-weight: var(--fw-bold);
  text-decoration: none;
  width: unset;
  height: 48px;
  background: transparent;
  transition: all 0.12s ease-in-out;
  color: var(--color-neutral-10);
  cursor: pointer;
}

.btn-default .icon {
  width: var(--navbar-icon-size-rg);
  height: var(--navbar-icon-size-rg);
  fill: var(--color-brand-primary-dark);
  color: var(--color-brand-primary-dark);
}

.btn-default:hover,
.btn-default:focus {
  border-color: var(--color-neutral-20);
  background-color: var(--color-neutral-20);
  color: var(--color-neutral-90);
}

.btn-default:hover .icon,
.btn-default:focus .icon {
  fill: var(--color-brand-primary-dark);
  color: var(--color-brand-primary-dark);
}

.btn-default .btn-label {
  display: flex;
  align-items: center;
  grid-gap: 1.2rem;
}

.btn-default-light {
  border: 0;
  display: inline-flex;
  align-items: center;
  grid-gap: 0.4rem;
  border-radius: 2rem;
  padding: 0.8rem 1.4rem;
  font-size: var(--fs-rg);
  font-weight: var(--fw-bold);
  background-color: rgba(var(--color-neutral-90-rgb), 0.1);
  color: var(--color-neutral-80);
  transition: all 0.16s ease-in-out;
}

.btn-default-light .icon {
  width: var(--navbar-icon-size-rg);
  height: var(--navbar-icon-size-rg);
}

.btn-default-light:hover,
.btn-default-light:focus {
  background-color: var(--color-neutral-20);
  color: var(--color-neutral-90);
}

.btn-default-light:hover .icon,
.btn-default-light:focus .icon {
  fill: var(--color-brand-primary-dark);
  color: var(--color-brand-primary-dark);
}

.btn-primary {
  border: solid 2px var(--color-brand-primary);
  background-color: var(--color-brand-primary);
  color: var(--color-neutral-80);

  border-radius: 2rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.4rem;
  padding: 0.8rem 1.4rem;
  font-size: var(--fs-rg);
  font-weight: var(--fw-bold);
  text-decoration: none;
  width: unset;
  height: 48px;
  transition: all 0.12s ease-in-out;
  cursor: pointer;
}

.btn-primary .icon {
  width: var(--navbar-icon-size-rg);
  height: var(--navbar-icon-size-rg);
  fill: var(--color-neutral-90);
  color: var(--color-neutral-90);
}

.btn-primary:hover,
.btn-primary:focus {
  border-color: var(--color-neutral-20);
  background-color: var(--color-neutral-20);
  color: var(--color-neutral-90);
}

.btn-primary:hover .icon,
.btn-primary:focus .icon {
  fill: var(--color-brand-primary-dark);
  color: var(--color-brand-primary-dark);
}

.btn-primary .btn-label {
  display: flex;
  align-items: center;
  grid-gap: 1.2rem;
}

.btn-secondary-light {
  border: solid 2px var(--color-neutral-80);
  background-color: var(--color-neutral-80);
  color: var(--color-neutral-20);

  border-radius: 2rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.4rem;
  padding: 0.8rem 1.4rem;
  font-size: var(--fs-rg);
  font-weight: var(--fw-bold);
  text-decoration: none;
  width: unset;
  height: 48px;
  transition: all 0.12s ease-in-out;
  cursor: pointer;
}

.btn-secondary-light .icon {
  width: var(--navbar-icon-size-rg);
  height: var(--navbar-icon-size-rg);
  fill: var(--color-brand-primary);
  color: var(--color-brand-primary);
}

.btn-secondary-light:hover,
.btn-secondary-light:focus {
  border-color: var(--color-neutral-20);
  background-color: var(--color-neutral-20);
  color: var(--color-neutral-90);
}

.btn-secondary-light:hover .icon,
.btn-secondary-light:focus .icon {
  fill: var(--color-brand-primary-dark);
  color: var(--color-brand-primary-dark);
}

.btn-secondary-light .btn-label {
  display: flex;
  align-items: center;
  grid-gap: 1.2rem;
}

.btn-size--sm {
  padding: 0.6rem 1.2rem;
  height: 42px;
  font-size: var(--fs-sm);
}

.btn-size--sm .icon {
  width: var(--navbar-icon-size-sm);
  height: var(--navbar-icon-size-sm);
}

.create-button-badge {
  background-color: var(--color-neutral-60);
  border-radius: 0px 6px 6px 0px;
  margin: -14.8px 0px 0px -1px;
  color: var(--color-neutral-10);
  padding: 1.5px 5px 0px 5px;
  height: 16.5px;
  transform: skew(170deg);
  font-size: var(--fs-xxs);
  text-transform: uppercase;
}

/* Scroll-bar */
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #00f2fe;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  height: 100px;
}

::-webkit-scrollbar {
  width: 25px;
}
