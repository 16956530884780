/* CSS */
.Actions-button {
  align-items: center;
  background-color: #06f;
  color: white;
  border: 2px solid #06f;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -0.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 15px 15px;
}

.Actions-button:focus {
  color: #171e29;
}

.Actions-button:hover {
  background-color: #3385ff;
  border-color: #3385ff;
  fill: #06f;
}

.Actions-button:active {
  background-color: #3385ff;
  border-color: #3385ff;
  fill: #06f;
}

@media (min-width: 768px) {
  .Actions-button {
    min-width: 170px;
  }
}
