/* enter stlyes here */
@import url('https://fonts.googleapis.com/css2?family=Mogra&family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Slackey&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Coiny&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.tube-tunes-container {
  height: 100vh;
  color: white;
  background-image: radial-gradient(
    circle 674px at 18.3% 77%,
    rgba(139, 186, 244, 1) 3.4%,
    rgba(15, 51, 92, 1) 56.6%
  );
  margin-top: -1rem;
}

.tube-tunes-app {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 1rem;
}

.tube-tunes-logo {
  font-family: 'Mogra', cursive;
  font-weight: bold;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.tube-tunes-logo .tube-tunes-logoImg {
  width: 90px;
}

.tube-tunes-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
}

.tube-tunes-content_title {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Slackey', cursive;
}

a.tube-tunes-tooltip {
  font-family: 'Slackey', cursive;
}

.tube-tunes-content_title span {
  font-family: 'Slackey', cursive;
}

.tube-tunes-tooltip {
  display: inline;
  position: relative;
  color: white;
  text-decoration: none;
}

.tube-tunes-tooltip:hover:after {
  background: #fff3f3;
  border-radius: 8px;
  color: #000;
  content: attr(title);
  margin: -82px auto 0;
  font-size: 16px;
  padding: 13px;
  width: 257px;
  position: absolute;
  left: -4rem;
  top: 17px;
}

.tube-tunes-tooltip:hover:before {
  border: solid;
  border-color: #fff transparent;
  border-width: 12px 6px 0 6px;
  content: '';
  left: 45%;
  bottom: 57px;
  position: absolute;
}

.tube-tunes-content_description {
  font-weight: 400;
  font-size: 20px;
  opacity: 0.9;
  margin-bottom: 1rem;
  text-align: center;
  font-family: 'Secular One', sans-serif;
}

.tube-tunes-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tube-tunes-form .tube-tunes-form_input {
  max-width: 500px;
  min-width: 300px;
  height: 38px;
  background: white;
  outline: none;
  border: none;
  text-indent: 10px;
  font-size: 16px;
  border-radius: 3px;
  margin-bottom: 1rem;
}

.tube-tunes-form .tube-tunes-form_button {
  width: 100px;
  height: 30px;
  background: #4158b0;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  margin-bottom: 3rem;
  border-radius: 3px;
}

.tube-tunes-download_btn {
  color: #f5ff00;
  padding: 2px 14px;
  border-radius: 5px;
  font-weight: bolder;
  text-decoration: underline;
}

.tube-tunes-invalidLink {
  font-family: 'Coiny', cursive;
  color: #ffe400;
  font-size: 34px;
}

.tube-tunes-taking-time {
  color: yellow;
  font-size: 20px;
  text-align: center;
  font-family: 'Coiny';
}

@media screen and (max-width: 600px) {
  .tube-tunes-content {
    margin-top: 10rem;
  }

  .tube-tunes-content_title {
    font-size: 40px;
  }
}

@media (max-width: 860px) {
  .tube-tunes-content {
    margin-top: 3rem;
  }
}

@media (max-width: 420px) {
  .tube-tunes-form .tube-tunes-form_input {
    min-width: 240px;
  }
  .tube-tunes-invalidLink {
    font-size: 20px;
  }

  .tube-tunes-result {
    font-size: 12px;
  }
}
