/* Search bar */
.app-header-search {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
  max-width: 600px;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .app-header-search {
    width: 70%;
  }
}

/* Filter*/
.filter {
  width: 100%;
  padding: 0.5rem 1.6rem;
  height: 100%;
}

.filter .filter-area {
  background-color: rgba(var(--color-brand-primary-rgb), 0.2);
  color: var(--color-neutral-20);
  font-family: var(--ff-default);
  font-size: var(--fs-rg);
  border-radius: 2rem;
  height: 100%;
}

.filter button {
  color: var(--color-neutral-20);
}

.search-input .search-input-text {
  box-sizing: border-box;
  width: 100%;
  border: 0;
  background-color: transparent;
  color: var(--color-neutral-20);
  font-family: var(--ff-default);
  font-size: var(--fs-rg);
  line-height: 1;
}

.filter .search-input-text:focus,
.filter .search-input-text:focus-visible {
  box-shadow: none;
  outline: none;
  border: none;
}

.filter .search-input-text::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-neutral-10);
  opacity: 0.5; /* Firefox */
}

.filter .search-input-text:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-neutral-10);
}

.filter .search-input-text::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-neutral-10);
}

@media screen and (max-width: 1217px) {
  .filter {
    display: grid;
    justify-content: start;
  }
}

@media screen and (max-width: 576px) {
  .filter .search-input-icon {
    left: 0.6rem;
    width: 20px;
    height: 20px;
  }

  .filter.search-input-text {
    padding: 0.6rem 1.2rem 0.6rem 2rem;
  }
  .flex-filter-modal {
    width: 100% !important;
  }
  .modal-filter .modal-filter-header {
    padding: 0rem 1.6rem 1.6rem 1.4rem !important;
  }
}

/* Filter */
.search-filter {
  height: 28px;
}

.btn-filter {
  position: relative;
  border: 0;
  padding: 0 0.48rem;
  background-color: transparent;
  cursor: pointer;
}

.btn-filter .icon {
  fill: var(--color-neutral-30);
}

.btn-filter:hover,
.btn-filter:focus {
  outline: none;
}

.btn-filter:hover .icon,
.btn-filter:focus .icon {
  fill: var(--color-neutral-10);
}

.btn-filter .badge {
  position: absolute;
  top: 4px;
  right: 2px;
  width: 18px;
  height: 18px;
  transform: translateY(-50%);
  border-radius: 18px;
  color: var(--color-neutral-90);
  font-weight: var(--fw-bold);
  font-size: var(--fs-xs);
  line-height: 18px;
  background-color: var(--color-brand-primary);
}

@media screen and (max-width: 576px) {
  .btn-filter {
    padding: 0 0.2rem;
  }
  .btn-filter .icon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 494px) {
  .filter {
    display: grid;
    justify-content: center;
  }
}

@media screen and (max-width: 408px) {
  .filter .filter-area {
    width: 198px;
  }
}

/* Filters Modal */
.modal-filter {
  position: fixed;
  left: 5%;
  top: 10%;
  z-index: 100;
  display: flex;
  flex-direction: column;

  /* Probably need media queries here */
  width: 90%;
  max-height: 90%;
  border-radius: 0.2rem;

  /* transform: translate(-30%, -30%); */

  background: white;
  box-shadow: 0px 0 26px 0px rgba(var(--color-neutral-90-rgb), 0.62);
  -webkit-box-shadow: 0px 0 26px 0px rgba(var(--color-neutral-90-rgb), 0.62);
  -moz-box-shadow: 0px 0 26px 0px rgba(var(--color-neutral-90-rgb), 0.62);
}

.modal-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.72);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.modal-filter .modal-filter-header {
  padding: 1rem 1.6rem 1.6rem 1.4rem;
}
.modal-filter .modal-filter-header .modal-title {
  margin: 0;
  font-size: var(--fs-md);
  font-weight: var(--fw-bold);
}

.modal-filter .modal-filter-body {
  padding: 0 1.6rem 1rem 1.6rem;
  flex-grow: 1;
}

.modal-filter .modal-filter-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-gap: 0.6rem;
  padding: 1rem 1.6rem 1.4rem 1.6rem;
}

.modal-filter .form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.modal-filter .form-group:last-child {
  margin-bottom: 0;
}

.modal-filter .form-group label {
  margin: 0.2rem 0;
  color: var(--color-neutral-70);
  font-size: var(--fs-sm);
  font-weight: var(--fw-regular);
}

.modal-filter .form-group .form-control {
  border: solid 1px var(--color-neutral-40);
  font-size: var(--fs-rg);
  padding: 0.4rem 0.3rem;
}

.modal-filter .form-group .form-control option {
  padding: 1.6rem;
  font-size: var(--fs-rg);
}

.flex-filter-modal {
  display: flex;
  width: 500px;
  margin: 0 auto;
  justify-content: space-between;
}

/* CSS attributes for clear All button */

.modal-clear-filter {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.clear-all-filter-btn {
  font-size: smaller;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
}

.clear-all-filter-btn:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}
