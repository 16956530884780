.head {
  background-color: #f5ba13;
  margin: auto -16px;
  padding: 16px 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.headsHeading {
  color: #fff;
  font-weight: 200;
}

.note {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 5px #ccc;
  padding: 10px;
  width: 240px;
  margin: 16px;
  float: left;
}

.notesHeading {
  font-size: 1.1em;
  margin-bottom: 6px;
}

.notesPara {
  font-size: 1.1em;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.notesButton {
  position: relative;
  float: right;
  margin-right: 10px;
  color: #f5ba13;
  border: none;
  width: 36px;
  height: 36px;
  cursor: pointer;
  outline: none;
}

.create-note {
  position: relative;
  width: 480px;
  margin: 30px auto 20px auto;
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
}

.create-note .create-input,
.create-note .create-text {
  width: 100%;
  border: none;
  padding: 4px;
  outline: none;
  font-size: 1.2em;
  resize: none;
}

.create-note .create-button {
  position: absolute;
  right: 18px;
  bottom: -18px;
  background: #f5ba13;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-button-disabled {
  position: absolute;
  right: 18px;
  bottom: -18px;
  background: #888888;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-button:hover {
  background-color: black;
}

/* Media Query */

@media (max-width: 514px) {
  .create-note {
    width: 21rem;
  }
}

@media (max-width: 358px) {
  .create-note {
    width: 13rem;
  }
}

@media (max-width: 304px) {
  .create-note {
    width: 12rem;
  }

  .note {
    width: 205px;
  }
}

@media (max-width: 269px) {
  .create-note {
    width: 11rem;
  }

  .note {
    width: 182px;
  }
}

@media (max-width: 269px) {
  .create-note {
    width: 9rem;
  }

  .note {
    width: 170px;
  }
}
