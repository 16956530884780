@import './variables';

.fun-quiz {
  &-screen {
    margin-top: 4rem;
    width: 100%;
    overflow: hidden;
    position: relative;
    color: $quiz-app-raw-black;
    display: block;

    @media screen and (max-width: 1366px) {
      .App {
        margin-top: 0rem;
      }
    }

    .loading-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .timer {
      border: 1px solid $quiz-app-black-color;
      border-radius: 50%;
      width: 100px;
      aspect-ratio: 1/1;
      display: grid;
      place-items: center;
      font-family: 'Russo One', sans-serif;
      font-size: 3rem;
      color: $quiz-app-black-color;
      margin-bottom: 2rem;
    }

    .caution {
      color: $quiz-app-raw-red;
    }

    .video {
      min-height: 100%;
      min-width: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
      aspect-ratio: 16/9;
    }

    .section {
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .question-info {
        background-color: $quiz-app-black-color;
        color: $quiz-app-white-color;
        padding: 8px 1rem;
        margin: 1rem;
        border-radius: 30px;
        margin-top: -8px;
        font-weight: bold;
      }

      .question {
        max-width: calc(820px - 50px);
        margin: 0 10px 1rem 10px;
        background-color: $quiz-app-light-gray2;
        box-shadow: inset 0px 0px 10px 1px $quiz-app-light-gray3;
        border-radius: 20px;
        border: 1px solid $quiz-app-border-color;

        h1 {
          margin: 0.5rem 2rem;
          color: $quiz-app-text-color;
          font-size: clamp(1rem, 5vw, 1.5rem);
        }
      }

      .options {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        row-gap: 30px;
        column-gap: 30px;
        margin-top: 1rem;

        .single-opt {
          margin: 0;
          padding: 0;
          width: 250px;
          display: grid;
          place-items: center;
        }

        .option-button {
          display: flex;
          align-items: center;
          min-width: 250px;
          min-height: 60px;
          font-size: 1rem;
          padding: 0.5rem 1.5rem;
          background-color: rgba(234, 227, 227, 1);
          border-radius: 40px;
          transition: 100ms ease-in-out;
          text-align: left;
          vertical-align: middle;
          cursor: pointer;

          &:hover {
            background-color: $quiz-app-green-color;
            color: $quiz-app-white-color;
          }
        }

        .blinking-options {
          animation: blinker 0.2s linear infinite;
          background-color: $quiz-app-light-gray;
          color: $quiz-app-black-color;

          @keyframes blinker {
            from {
              background-color: $quiz-app-light-gray;
              color: $quiz-app-black-color;
            }
            50% {
              background-color: $quiz-app-green-color;
              color: $quiz-app-white-color;
            }
            to {
              background-color: $quiz-app-light-gray;
              color: $quiz-app-black-color;
            }
          }
        }

        .active-option {
          background-color: $quiz-app-green-color;
          box-shadow: inset 0px 0px 10px 2px $quiz-app-transparent-black;
          color: $quiz-app-white-color;
        }
      }

      .confirm-button {
        outline: none;
        padding: 10px 2rem;
        font-size: 1.1rem;
        background-color: $quiz-app-deep-blue;
        cursor: pointer;
        border: none;
        border-radius: 30px;
        color: $quiz-app-white-color;
        font-weight: 600;

        &:hover {
          background-color: $quiz-app-deep-blue2;
          box-shadow: inset 0px 0px 10px 2px $quiz-app-transparent-black;
        }
      }

      .cheat-button {
        outline: none;
        border: none;
        background-color: transparent;
        font-weight: normal;
        color: $quiz-app-deep-blue;
        text-decoration: underline;
        cursor: pointer;
      }

      @media screen and (max-width: 600px) {
        .options {
          grid-template-columns: 0fr;
          grid-template-rows: repeat(4, 1fr);
          row-gap: 20px;
          column-gap: 20px;
        }
        .question {
          width: calc(100% - 30px);
          overflow: hidden;
        }
      }

      .footer {
        margin: 1rem 0;
        width: 540px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .link {
          outline: none;
          border: none;
          background-color: transparent;
          font-size: 1rem;
          color: $quiz-app-deep-blue;
          cursor: pointer;
          font-weight: 600;
        }
        @media screen and (max-width: 600px) {
          width: 100%;
          height: 100px;
          flex-direction: column;
          justify-content: center;
          gap: 1rem;
        }
      }
    }
  }
}
