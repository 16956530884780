.rand-quote-gen h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
.rand-quote-gen .play-area {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: fit-content;
}

.rand-quote-gen .play-area .quote-area {
  min-height: 15rem;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rand-quote-gen .play-area .quote-area .quote {
  height: 100%;
  width: 55%;
  font-size: 2rem;
  justify-items: center;
  margin-left: 25%;
}

.rand-quote-gen .play-area .quote-area .page-author {
  width: 45%;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.rand-quote-gen .play-area .quote-area .page-author {
  margin-left: 30%;
}

.rand-quote-gen .play-area .quote-area .page-author quote-author .author {
  float: right;
}

.rand-quote-gen .play-area .change-btn {
  width: 5rem;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.rand-quote-gen .play-area .change-btn:hover:enabled {
  background-color: rgb(193, 192, 192);
}

@media only screen and (max-width: 760px) {
  .rand-quote-gen .play-area .quote-area .quote {
    font-size: 1rem;
    width: 100%;
    margin-left: 0;
    padding: 0 10px 0 10px;
  }

  .rand-quote-gen .play-area .change-btn {
    width: 3rem;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  .rand-quote-gen .play-area .quote-area {
    min-height: 10rem;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rand-qutote-gen .play-area {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: fit-content;
    width: 100%;
  }

  .rand-quote-gen .play-area .quote-area .page-author {
    margin-left: 0;
    width: 100%;
    padding: 0 10px 0 10px;
  }

  .rand-quote-gen .play-area .change-btn:hover {
    background-color: rgb(193, 192, 192);
  }
}
