.steps {
  width: 600px;
  background-color: #f7f7f7;
  border-radius: 7px;
  padding: 25px 100px;
  margin: 100px auto;
}

.steps_numbers {
  display: flex;
  justify-content: space-between;
}

.steps_numbers > div {
  height: 40px;
  aspect-ratio: 1;
  background-color: #e7e7e7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.steps_numbers .steps_active {
  background-color: #7950f2;
  color: #fff;
}

.steps_message {
  text-align: center;
  font-size: 20px;
  margin: 40px 0;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.steps_buttons {
  display: flex;
  justify-content: space-between;
}

.steps_buttons button {
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 8rem;
}

.steps_buttons button span {
  font-size: 16px;
  line-height: 1;
}

.steps_message h3 {
  margin: 0;
  text-transform: uppercase;
}
