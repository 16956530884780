@import '../../variables';

.simple-live-chat-body {
  padding-bottom: 0;
  margin-left: 0.5rem;
  font-family: 'Noto Sans', sans-serif;
}

.simple-live-chat-message {
  display: flex;
  gap: 0.5rem;
  margin: 0.75rem 0;
}

.simple-live-chat-user {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid $simple-user-border-color;
  background-color: $simple-button-background-color;
}

.simple-live-chat-message-sender {
  justify-content: flex-end;
}

.simple-live-chat-message-sender-text {
  background-color: $simple-message-color;
  color: $simple-button-background-color;
  margin-left: 2.5rem;
  word-wrap: break-word;

  @media screen and (min-width: 640px) {
    width: auto;
  }

  span {
    display: block;
    text-align: right;
    font-style: italic;
    font-size: 12px;
  }
}

.simple-live-chat-date-display {
  width: 100%;
  display: grid;
  place-items: center;
  margin: 1rem 0;
  font-size: 14px;
  line-height: 1.25rem;

  p {
    display: inline;
    padding: 0rem 0.5rem;
    border-radius: 0.5rem;
    background-color: $simple-date-background;
  }
}

.simple-live-chat-text-right {
  text-align: right;
  font-size: 12px;
  color: $simple-text-color;
  font-weight: 500;
}
.simple-live-chat-text-left {
  text-align: left;
  font-size: 12px;
  color: $simple-text-color;
  font-weight: 500;
}

.simple-live-chat-message-context {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  max-width: 300px;
  margin-right: 0px;
  border: 1px solid;
  word-wrap: break-word;

  @media screen and (max-width: 640px) {
    width: auto;
    font-size: 0.9rem;
  }
}

.simple-live-chat-message-receiver {
  justify-content: flex-start;

  .simple-live-chat-message-receiver-text {
    background-color: $simple-message-receive-background;
    margin-right: 2.5rem;
    border: 0px;
    word-wrap: break-word;

    span {
      display: block;
      text-align: right;
      font-style: italic;
      font-size: 12px;
    }
  }
}
