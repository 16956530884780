.play-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.play-container h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 0;
}
.card-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.Expanding-card {
  width: 80px;
  height: 400px;
  cursor: pointer;
  margin: 20px;
  position: relative;
  transition: width 1s;
}

.card-container .active {
  width: 100%;
}
.Expanding-card-imgage {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 50px;
}
.info {
  position: absolute;
  bottom: 10%;
  left: 5%;
  opacity: 0;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  transition: opacity 2s;
  background-color: rgba(27, 27, 27, 0.5);
  padding: 0 5%;
  border-radius: 10px;
}
.active .info {
  opacity: 1;
}
@media only screen and (max-width: 600px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }
  .Expanding-card {
    width: 100%;
    height: 50px;
    transition: height 1s;
  }
  .Expanding-card .Expanding-card-imgage {
    height: 50px;
    transition: height 1s;
  }
  .card-container .active {
    height: 250px;
  }
  .active .Expanding-card-imgage {
    height: 250px;
  }
  .info {
    bottom: 5%;
    font-size: 25px;
    transition: opacity 1s;
  }
  .Expanding-card-imgage {
    border-radius: 30px;
  }
}
