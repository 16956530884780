:root {
  --color-primary: #243a73;
  --color-secondary: #f2ebe9;
}

.todo-play-body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--color-primary);
  /* background-image: linear-gradient(
    0deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(253, 187, 45, 1) 100%
  ); */
  color: var(--color-secondary);
  font-family: 'Poppins', sans-serif;
}

.todo-top-banner {
  font-size: 2rem;
}

.todo-top-banner,
.react-todo-form {
  /* min-height: 20vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-todo-form input,
.react-todo-form button {
  padding: 0.5rem;
  font-size: 1.2rem;
  border: none;
  background: var(--color-secondary);
}

.react-todo-form input {
  color: var(--color-primary);
}

.react-todo-form input:focus {
  outline: none;
}

.react-todo-input {
  position: relative;
}

.react-todo-form button {
  color: var(--color-secondary);
  /* background: #f7fffe; */
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(9, 147, 113);
  cursor: pointer;
  transition: all 0.3s ease;
}

.react-todo-form button:hover {
  background: #ff6f47;
  color: var(--color-secondary);
}

.todo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -3rem;
}

.todo-list {
  min-width: 30%;
  list-style: none;
}

.todo {
  margin: 0.5rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 1s ease;
}

.filter-todo {
  padding: 0.8rem;
}

.todo li {
  flex: 1;
}

.trash-btn,
.complete-btn,
.edit-btn {
  background: #ff6f47;
  color: var(--color-secondary);
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
}

.complete-btn {
  background: rgb(9, 147, 113);
}

.todo-item {
  padding: 0.5rem;
  width: 350px;
  word-wrap: break-word;
}

.edit-btn {
  background: #590696;
}
.fa-trash,
.fa-check {
  pointer-events: none;
}

.fall {
  transform: translateY(10rem) rotateZ(20deg);
  opacity: 0;
}

.completed {
  text-decoration: line-through;
  opacity: 0.5;
}

/*CUSTOM SELECTOR */
.select-todo .options {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  height: 50px;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
  color: #ff6f47;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  width: 12rem;
}

.todo-input {
  height: 50px;
}

.todo-button {
  height: 48px;
  width: 40px;
  transform: translateX(-100%);
}

/* Custom Select */
.select-todo {
  margin: 1rem;
  position: relative;
  overflow: hidden;
}

/* Arrow */
.select-todo::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem;
  background: #ff6f47;
  cursor: pointer;
  pointer-events: none;
}

@media screen and (max-width: 576px) {
  .react-todo-form {
    flex-direction: column;
  }

  .todo {
    width: 80vw;
  }

  .todo-item {
    width: 50%;
  }
}

@media screen and (max-width: 360px) {
  .react-todo-form input,
  .react-todo-input {
    width: 80vw;
  }
  .todo-top-banner {
    font-size: 1.5rem;
  }
}
