.react-chatbot-kit-user-chat-message-container {
  display: flex;
  margin: 45px 0 10px 0;
  justify-content: flex-end;
  position: relative;
}

.react-chatbot-kit-user-avatar-container {
  display: hidden;
}

.react-chatbot-kit-user-avatar-icon {
  display: hidden;
}

.react-chatbot-kit-user-chat-message {
  background-color: #e7f1fd;
  padding: 10px;
  border-radius: 12px;
  font-size: 0.9rem;
  color: #222230;
  font-weight: 500;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-chat-bot-message-container {
  margin: 5px 0;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
  margin-right: 12.5px;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-chat-bot-avatar-icon {
  fill: #494646;
  width: 22.5px;
  height: 22.5px;
}

.react-chatbot-kit-chat-bot-avatar-letter {
  color: #1d1d1d;
  margin: 0;
  padding: 0;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #f7f7fc;
  padding: 10px;
  border-radius: 12px;
  font-size: 0.9rem;
  color: #222230;
  font-weight: 500;
  position: relative;
  width: 270px;
  margin-left: auto;
  text-align: left;
}

@media (min-width: 640px) {
  .react-chatbot-kit-chat-bot-message {
    width: 330px;
  }
}

.react-chatbot-kit-chat-bot-loading-icon-container {
  height: 17px;
  width: 25px;
}

.chatbot-loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

#chatbot-loader #chatbot-loader-dot1 {
  animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.react-chatbot-kit-chat-container {
  position: relative;
  background-color: #fff;
  width: 360px;
  height: 610px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

@media (min-width: 640px) {
  .react-chatbot-kit-chat-container {
    width: 428px;
  }
}

.react-chatbot-kit-chat-inner-container {
  height: 500px;
  background-color: #fff;
  border-radius: 3px;
  border-radius: 5px;
}

.react-chatbot-kit-chat-header {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #ffff;
  font-family: Arial;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #514f4f;
  padding: 12px;
  font-weight: bold;
  border-radius: 12px;
}

.react-chatbot-kit-chat-input-container {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 84px;
  display: flex;
  background-color: #fff;
  border-radius: 12px;
}

.react-chatbot-kit-chat-message-container {
  padding: 16px 12px;
  overflow: scroll;
  height: 426px;
  position: relative;
}

.react-chatbot-kit-chat-input {
  width: 100%;
  padding: 12.5px;
  border: none;
  font-size: 0.85rem;
  font-weight: 500;
  border-top: 1px solid #d8d8d8;
  border-bottom-left-radius: 5px;
}

.react-chatbot-kit-chat-input-form {
  width: 100%;
  height: 84px;
}

.react-chatbot-kit-chat-input::placeholder {
  color: #585858;
}

.react-chatbot-kit-chat-input:focus-visible {
  outline: none;
  box-shadow: none;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #52528017 !important;
  width: 24px;
  margin: 38px 0 0 0;
  height: 24px;
  border: none;
  color: #fff;
  border-bottom-right-radius: 5px;
  border-radius: 8px;
  position: absolute;
  bottom: 4px;
  right: 12px;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: gray;
  width: 11px;
  margin: 0 auto;
  transform: rotate(19deg);
}

.react-chatbot-kit-error {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
}

.react-chatbot-kit-error-container {
  width: 260px;
}

.react-chatbot-kit-error-header {
  font-size: 1.3rem;
  color: #1d1d1d;
  margin-bottom: 30px;
}

.react-chatbot-kit-error-docs {
  display: block;
  margin: 25px auto;
  color: rgb(56, 104, 139);
  padding: 8px;
  border: 1px solid rgb(40, 152, 236);
  width: 130px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
}
