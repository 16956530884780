.org-tree {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.org-tree h2 {
  margin: 1rem 0 1.6rem 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.org-tree p {
  margin: 1rem 0;
}

.org-tree .about {
  max-width: 40rem;
}

.org-tree .node {
  font-size: 1.5rem;
}

@media only screen and (max-width: 500px) {
  .org-tree .node {
    font-size: 0.5rem;
  }
}
