.quizeo .menu-item {
  display: flex;
  position: relative;
  margin: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18rem;
  border-width: 4px;
  opacity: 0.9;
}

.quizeo .image-container {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 18rem;
}

.quizeo .image-content {
  position: absolute;
  padding: 1.25rem;
  background-color: #ffffff;
  color: #000000;
  font-weight: 600;
  border-width: 2px;
  cursor: pointer;
  opacity: 0.8;
}

.quizeo .image-content h1 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
