.notehack-play .add-note-container {
  padding: 20px 15px;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 20px auto;
  box-shadow: var(--box-shadow);
}

.notehack-play .add-note-form {
  display: flex;
  gap: 7px;
}

.notehack-play .add-note-input {
  flex-grow: 1;
  padding: 5px 5px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  font-size: 18px;
}

.notehack-play .add-note-input:focus {
  outline: 0.5px solid #eaeaea;
}

.notehack-play .add-note-button {
  background-color: #03ba5c;
  color: #ffffff;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.notehack-play .add-note-button:hover {
  background-color: #03b056;
}
