.poke-main-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.poke-input-cont {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 30%;
}

.poke-input-field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.poke-input-field::placeholder {
  color: transparent;
}
.poke-input-field:placeholder-shown ~ .poke-form-label {
  cursor: text;
  top: 20px;
}

.poke-form-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}

.poke-input-field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e, #38ef7d);
  border-image-slice: 1;
}
.poke-input-field:focus ~ .poke-form-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #11998e;
  font-weight: 700;
}

.poke-input-field:required,
.poke-input-field:invalid {
  box-shadow: none;
}

.poke-stats-sumbit-btn {
  align-items: center;
  margin-top: 35px;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow:
    rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
    #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: 'JetBrains Mono', monospace;
  height: 35px;
  justify-content: center;
  line-height: 1;
  overflow: hidden;
  padding: 0px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition:
    box-shadow 0.15s,
    transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.poke-stats-sumbit-btn:focus {
  box-shadow:
    #d6d6e7 0 0 0 1.5px inset,
    rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
    #d6d6e7 0 -3px 0 inset;
}

.poke-stats-sumbit-btn:hover {
  box-shadow:
    rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
    #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.poke-stats-sumbit-btn:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

@media (max-width: 764px) {
  .poke-input-cont {
    width: 50%;
  }
}
