.text-avatar_container {
  align-self: center;
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  background-color: #f3f4fa;
  width: 100%;
  height: 50vh;
}
