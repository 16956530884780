/* enter stlyes here */
.image-carousel .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
}

.image-carousel .image-element {
  border-radius: 10px;
  position: relative;
  max-width: 80%;
}

.image-carousel .image-slider-body {
  height: 250px;
  width: 400px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.image-carousel .image-slider {
  position: absolute;
  top: 0;
  display: flex;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.image-carousel .image-slider > img {
  object-fit: cover;
}

.image-carousel .arrow {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(169, 169, 169, 0.6);
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-carousel .right-arrow {
  right: 0;
  transform: translate(50%, -50%);
}

.image-carousel .left-arrow {
  left: 0;
  transform: translate(-50%, -50%);
}

.image-carousel .dots {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.image-carousel .dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: gray;
  cursor: pointer;
}

.image-carousel .dot.active {
  background-color: black;
}
