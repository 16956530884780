.Smart-converter-Main-container {
  width: 90%;
  margin: auto;
}

/* Textform CSS */

.Smart-converter-Heading {
  font-size: 35px;
  margin: 30px 0px;
}
.Smart-converter-Header2 {
  font-size: 25px;
  font-weight: 600;
}
#Smart-converter-myBox-textarea {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 1px solid rgb(150, 150, 150);
}
.Smart-converter-btn {
  border: 1px solid blue;
}
.Smart-converter-btn:hover,
.Smart-converter-btn:focus {
  border: 1px solid blue;
}

@media only screen and (max-width: 600px) {
  .Smart-converter-Heading {
    font-size: 25px;
  }
}
/* Textform CSS */

/* Alert CSS */

.Smart-converter-alert {
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: rgb(50, 187, 91);
}

/* Alert CSS */
