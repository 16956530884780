$simple-button-shadow-color: rgba(0, 0, 0, 0.25);
$simple-button-border-color: #e5e5e5;
$simple-button-active-color: #868686;
$simple-error-color: #ef4444;
$simple-button-background-color: #fff;
$simple-message-color: #1d4ed8;

$simple-date-background: #bfdbfe;
$simple-message-receive-background: #e2e8f0;

$simple-header-color: #bae6fd;

$simple-text-color: #444444;
$simple-user-border-color: #94a3b8;
$simple-gray-color: gray;
$simple-default-background-color: #f6f6f9;
