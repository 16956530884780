.maincontanier {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url('background.jpg');
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.jokecontanier {
  padding: 20px;
  width: 600px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  margin: 30px 20px 20px;
  background-color: rgba(199, 183, 183, 0.4);
  font-size: x-large;
}

.jokecontanier:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

.dev-jokes-btn {
  padding: 1em 2.1em 1.1em;
  border-radius: 3px;
  margin: 8px;
  color: black;
  background-color: #c2fbd7;
  display: inline-block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 0.85em;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  box-shadow:
    rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.15) 0 1px 2px,
    rgba(44, 187, 99, 0.15) 0 2px 4px,
    rgba(44, 187, 99, 0.15) 0 4px 8px,
    rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
  position: relative;
}

.dev-jokes-btn:hover {
  box-shadow:
    rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px,
    rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px,
    rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}
@media screen and (max-width: 764px) {
  .jokecontanier {
    width: 75%;
    min-height: 40%;
    margin-top: 50px;
    font-size: larger;
    word-break: break-all;
  }
  .dev-jokes-btn {
    padding: 0.5rem 1rem 0.5rem;
  }
}
