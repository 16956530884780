.reactplay-loader {
  /* width: 100%;*/
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &--text {
    display: flex;
    flex-direction: column;
    &__title {
      font-size: 1.2rem;
    }
    &__subtitle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
    }
  }
}
