@import './variables';

.savingsCalculator__container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background-color: $background;
}

.savingsCalculator {
  display: flex;
  align-items: center;
  box-shadow:
    7px 7px 20px 5px rgba(0, 0, 0, 0.1),
    -7px -7px 20px 5px rgba(255, 255, 255, 0.868);
  border-radius: 16px;
  color: $text;
  padding: 4vh 3vw;
}

@media screen and (max-width: 1200px) {
  .savingsCalculator {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .savingsCalculator {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 600px) {
  .savingsCalculator {
    box-shadow: none;
    padding: 0%;
  }
}
