.suri-calc-display {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 20px;
  background: linear-gradient(90deg, #06beb6, #48b1bf 100%);
}

.suri-calc-expression {
  padding: 5px;
  margin-bottom: 10px;
  height: 50px;
}

.suri-calc-input,
.suri-calc-value {
  padding: 10px;
  text-align: right;
  background-color: transparent;
  color: white;
  border: 0px;
  letter-spacing: 1.5px;
}

.suri-calc-input {
  font-size: 35px;
}

.suri-calc-value {
  font-size: 15px;
}

.suri-calc-input::placeholder,
.suri-calc-value::placeholder {
  color: white;
}

.suri-calc-input:focus-visible,
.suri-calc-value:focus-visible {
  outline-style: none;
  outline-width: 0px;
}
