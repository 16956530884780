h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}
.pf-container,
.pf-form {
  display: grid;
  place-items: center;
}
.pf-form,
.pf-form > label {
  margin-top: 1.5rem;
}
.pf-form > label {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}
.pf-form > input[type='text'] {
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 0.375rem 0.75rem;
  width: 100%;
}
#btns {
  display: flex;
}
.pf-form-btn {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
  border-radius: 0.25rem;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  cursor: pointer;
  font-weight: 700;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-top: 1.25rem;
  padding: 0.5rem 1rem;
}
.pf-form-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}
.pf-table {
  border-width: 1px;
  min-width: 100%;
  text-align: center;
}
.pf-table-row {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-bottom-width: 1px;
}
.pf-table-col {
  --tw-text-opacity: 1;
  border-right-width: 1px;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  padding: 1rem 1.5rem;
  white-space: nowrap;
}
