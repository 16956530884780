/* List of Plays Available */
.list-plays {
  margin: 0 auto;
  padding: 1.6rem 2rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, minmax(var(--play-thumb-size-w), var(--play-thumb-size))); */
  /* grid-template-columns: repeat(auto-fill, var(--play-thumb-size-w));
    grid-auto-rows: var(--play-thumb-size-h)*2; */
  grid-gap: 2rem;
  justify-content: center;
}

.list-plays li a {
  display: block;
  position: relative;
  border-radius: 0.6rem;
  transition: all 0.16s ease-in-out;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0px 0 12px 0px rgba(var(--color-neutral-90-rgb), 0.03);
  -webkit-box-shadow: 0px 0 12px 0px rgba(var(--color-neutral-90-rgb), 0.03);
  -moz-box-shadow: 0px 0 12px 0px rgba(var(--color-neutral-90-rgb), 0.03);
}

.list-plays li a:hover,
.list-plays li a:focus {
  border-radius: 0.6rem;
  transform: scale(1.16);
  box-shadow: 0px 6px 12px 0px rgba(var(--color-neutral-90-rgb), 0.2);
  -webkit-box-shadow: 0px 6px 12px 0px rgba(var(--color-neutral-90-rgb), 0.2);
  -moz-box-shadow: 0px 6px 12px 0px rgba(var(--color-neutral-90-rgb), 0.2);
}

.list-plays a .play-header {
  padding: 1rem 1.4rem;
  background-color: var(--color-neutral-10);
}

.list-plays a .play-header .play-author {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.2rem;
  font-size: var(--fs-sm);
  font-weight: var(--fw-light);
  color: var(--color-neutral-50);
}

.list-plays a .play-header .play-author .author-anchor {
  font-weight: var(--fw-regular);
  color: var(--color-neutral-80);
}

.list-plays a .play-header .language {
  height: 1.25rem;
  width: 1.25rem;
  background-repeat: no-repeat;
  opacity: 0.2;
}

.list-plays li a:hover .play-header .language {
  opacity: 0.8;
}

.list-plays li a:hover .play-header .language-ts {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' data-name='Layer 1' fill='%232F73BF' viewBox='6 6 36 36'%3E%3Cpath d='M6,6V42H42V6ZM26.9,25H22.21V38.76h-3V25H14.57V22.46H26.9ZM32.29,39a9.42,9.42,0,0,1-4.41-1l.66-2.46a8.29,8.29,0,0,0,3.94,1c1.9,0,3-.89,3-2.22s-.82-2-2.89-2.71c-2.69-.95-4.41-2.39-4.41-4.73,0-2.67,2.23-4.68,5.77-4.68a8.66,8.66,0,0,1,3.91.81l-.71,2.4a7,7,0,0,0-3.26-.78c-1.89,0-2.69,1-2.69,2,0,1.25.94,1.84,3.11,2.67,2.84,1.08,4.2,2.5,4.2,4.84C38.47,36.74,36.49,39,32.29,39Z'/%3E%3C/svg%3E");
}

.list-plays a .play-header .language-ts {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' data-name='Layer 1' viewBox='6 6 36 36'%3E%3Cpath d='M6,6V42H42V6ZM26.9,25H22.21V38.76h-3V25H14.57V22.46H26.9ZM32.29,39a9.42,9.42,0,0,1-4.41-1l.66-2.46a8.29,8.29,0,0,0,3.94,1c1.9,0,3-.89,3-2.22s-.82-2-2.89-2.71c-2.69-.95-4.41-2.39-4.41-4.73,0-2.67,2.23-4.68,5.77-4.68a8.66,8.66,0,0,1,3.91.81l-.71,2.4a7,7,0,0,0-3.26-.78c-1.89,0-2.69,1-2.69,2,0,1.25.94,1.84,3.11,2.67,2.84,1.08,4.2,2.5,4.2,4.84C38.47,36.74,36.49,39,32.29,39Z'/%3E%3C/svg%3E");
}

.list-plays li a:hover .play-header .language-js {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23EFD81D' fill-rule='evenodd' d='M379.328 7337.432c-1.745 0-2.873-.832-3.423-1.92l1.53-.886c.403.658.926 1.141 1.853 1.141.778 0 1.275-.389 1.275-.926 0-.808-1.078-1.124-1.839-1.45-1.356-.577-2.256-1.302-2.256-2.833 0-1.409 1.074-2.483 2.753-2.483 1.194 0 2.054.416 2.671 1.503l-1.463.94c-.322-.577-.671-.805-1.208-.805-.551 0-.9.349-.9.805 0 .564.349.792 1.155 1.141 1.689.724 2.967 1.293 2.967 3.155 0 1.692-1.329 2.618-3.115 2.618zm-4.328-2.833c0 1.947-1.199 2.976-2.864 2.976-1.504 0-2.405-.575-2.848-1.575h-.026l1.529-1.069c.295.523.561.894 1.205.894.618 0 1.004-.313 1.004-1.252V7328h2v6.599zM364 7339h20v-20h-20v20z' transform='translate(-364 -7319)'/%3E%3C/svg%3E");
}

.list-plays a .play-header .language-js {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M379.328 7337.432c-1.745 0-2.873-.832-3.423-1.92l1.53-.886c.403.658.926 1.141 1.853 1.141.778 0 1.275-.389 1.275-.926 0-.808-1.078-1.124-1.839-1.45-1.356-.577-2.256-1.302-2.256-2.833 0-1.409 1.074-2.483 2.753-2.483 1.194 0 2.054.416 2.671 1.503l-1.463.94c-.322-.577-.671-.805-1.208-.805-.551 0-.9.349-.9.805 0 .564.349.792 1.155 1.141 1.689.724 2.967 1.293 2.967 3.155 0 1.692-1.329 2.618-3.115 2.618zm-4.328-2.833c0 1.947-1.199 2.976-2.864 2.976-1.504 0-2.405-.575-2.848-1.575h-.026l1.529-1.069c.295.523.561.894 1.205.894.618 0 1.004-.313 1.004-1.252V7328h2v6.599zM364 7339h20v-20h-20v20z' transform='translate(-364 -7319)'/%3E%3C/svg%3E");
}

.list-plays a .play-title {
  position: relative;
  margin-bottom: 0.3rem;
  font-size: var(--fs-rg);
  font-weight: var(--fw-bold);
  color: var(--color-neutral-90);
  z-index: 1;
  line-height: var(--lh-rg);
  transition: all 0.16s ease-in-out;
}
.list-plays a .play-thumb {
  position: relative;
  width: var(--play-thumb-size-w);
  height: var(--play-thumb-size-h);
  border-radius: 0.6rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
}
.list-plays a .play-thumb-img {
  position: relative;
  width: var(--play-thumb-size-w);
  height: var(--play-thumb-size-h);
  border-radius: 0.6rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--color-neutral-10);
}

.list-plays a .play-thumb:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  transition: all 0.16s ease-in-out;
}

.list-plays a:hover .play-thumb:after {
  background: rgba(var(--color-neutral-90-rgb), 0.9);
}

.list-plays a .play-status {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  top: 2rem;
  left: 0%;
  transform: translateX(-50%);
  color: var(--color-brand-primary);
  transition: all 0.16s ease-in-out;
  z-index: 1;
  grid-gap: 0.4rem;
}

.list-plays a:hover .play-status {
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
}

.list-plays a .play-status .current {
  display: none;
}

.list-plays .active a .play-status .current {
  display: block;
  font-size: var(--fs-sm);
}

.list-plays .play-actions .action.counted {
  display: flex;
  width: auto;
  height: auto;
  text-decoration: none;
  grid-gap: 0.2rem;
  align-items: center;
}

.list-plays .play-actions .action.counted .icon {
  fill: rgba(var(--color-neutral-90-rgb), 0.4);
}

.list-plays .play-actions .action.counted .count-value {
  margin-top: 2px;
  font-size: var(--fs-rg);
  font-weight: var(--fw-semibold);
  color: rgba(var(--color-neutral-90-rgb), 0.4);
}

/* Play Details */
.play-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  border-top: solid 1px var(--color-neutral-30);
}

.play-details .play-details-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--color-neutral-10);
  padding: 0.6rem 1.2rem 0.6rem 0.8rem;
  box-shadow: 0px 4px 10px 0px rgba(var(--color-neutral-90-rgb), 0.12);
  -webkit-box-shadow: 0px 4px 10px 0px rgba(var(--color-neutral-90-rgb), 0.12);
  -moz-box-shadow: 0px 4px 10px 0px rgba(var(--color-neutral-90-rgb), 0.12);
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .list-plays {
    padding: 2rem 1.6rem;
  }

  .list-plays a .play-header .play-author {
    font-size: var(--fs-rg);
  }

  .list-plays a .play-header .language {
    height: 1.5rem;
    width: 1.5rem;
  }

  .list-plays a .play-title {
    margin-bottom: 0.6rem;
    font-size: var(--fs-md);
  }
  .list-plays a .play-thumb {
    width: var(--play-thumb-size-w-mobile);
    height: var(--play-thumb-size-h-mobile);
  }
  .list-plays a .play-thumb-img {
    width: var(--play-thumb-size-w-mobile);
    height: var(--play-thumb-size-h-mobile);
  }
}

@media screen and (max-width: 768px) {
  .list-plays {
    padding: 2rem 1.4rem;
  }

  .list-plays a .play-header .play-author {
    font-size: var(--fs-rg);
  }

  .list-plays a .play-header .language {
    height: 1.5rem;
    width: 1.5rem;
  }

  .list-plays a .play-title {
    margin-bottom: 0.6rem;
    font-size: var(--fs-md);
  }
  .list-plays a .play-thumb {
    width: var(--play-thumb-size-w-mobile);
    height: var(--play-thumb-size-h-mobile);
  }
  .list-plays a .play-thumb-img {
    width: var(--play-thumb-size-w-mobile);
    height: var(--play-thumb-size-h-mobile);
  }
}

@media screen and (max-width: 480px) {
  .play-details .play-details-header {
    flex-direction: column;
  }
}

.play-details .play-details-header .header-leftcol {
  display: flex;
  flex-direction: row;
}

.play-details .play-details-header .header-leftcol-action {
  padding-top: 0.3rem;
  padding-right: 0.3rem;
}

.play-details .play-details-header .header-leftcol-action .action {
  display: block;
  margin-top: 1px;
}

.play-details .play-details-header .header-leftcol-action .action .icon {
  fill: rgba(var(--color-neutral-90-rgb), 0.4);
}

.play-details .play-details-header .header-leftcol-action .action:hover .icon,
.play-details .play-details-header .header-leftcol-action .action:focus .icon {
  fill: rgba(var(--color-neutral-90-rgb), 0.8);
}
.play-details .play-details-header .list-tags {
  flex-wrap: wrap;
}
@media screen and (max-width: 1172px) {
  .play-details .play-details-header .header-leftcol-action {
    padding-top: 0.16rem;
  }
}

@media screen and (max-width: 768px) {
  .play-details .play-details-header .header-leftcol-action {
    padding-top: 0.06rem;
  }
  .play-details .play-details-header .header-leftcol-action svg {
    width: 20px;
  }
}

.play-details .play-details-header .header-leftcol-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 0.1rem;
}

@media screen and (max-width: 480px) {
  .play-details .play-details-header .header-leftcol {
    grid-gap: 0.3rem;
  }

  .play-details .play-details-header .header-leftcol-action {
    padding-top: 0.1rem;
  }
}

.play-details .play-details-header .header-rightcol {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 0.5rem;
  padding-top: 0.4rem;
}

.header-rightcol {
  max-width: 70%;
}

.collapse-btn {
  border: 1px solid #010326;
  color: #010326;
}

.play-details .play-details-header .header-primary {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.2rem 0.6rem;
  flex-wrap: wrap;
}

@media screen and (max-width: 480px) {
  .play-details .play-details-header .header-rightcol {
    flex-direction: column-reverse;
    align-items: flex-start;
    border-top: solid 1px var(--color-neutral-30);
    margin-top: 0.4rem;
    padding-top: 0.4rem;
  }
  .header-rightcol{
    max-width: 95%;
  }

  .play-details .play-details-header .header-rightcol .header-desc {
    text-align: left;
    margin-right: 1rem;
    line-height: var(--lh-rg);
  }
}

.play-level {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.2rem;
  line-height: 1;
  border-radius: 0.2rem;
  font-size: var(--fs-xs);
  font-weight: var(--fw-bold);
  text-transform: uppercase;
  background-color: rgba(var(--color-neutral-90-rgb), 0.12);
  padding: 0.3rem 0.4rem 0.24rem 0.4rem;
  color: rgba(var(--color-neutral-90-rgb), 0.8);
  text-decoration: none;
  letter-spacing: 0.04rem;
  transition: all 0.12s ease-in-out;
}

.play-level.play-level-1 {
  background-color: rgba(var(--color-play-level-1), 0.12);
  color: rgba(var(--color-play-level-1), 1);
}
/* .play-level.play-level-1:hover,
.play-level.play-level-1:focus {
    background-color: rgba(var(--color-play-level-1),1);
    color: var(--color-neutral-10);
} */

.play-level.play-level-2 {
  color: rgba(var(--color-play-level-2), 1);
  background-color: rgba(var(--color-play-level-2), 0.12);
}
/* .play-level.play-level-2:hover,
.play-level.play-level-2:focus {
    background-color: rgba(var(--color-play-level-2),1);
    color: var(--color-neutral-10);
} */

.play-level.play-level-3 {
  color: rgba(var(--color-play-level-3), 1);
  background-color: rgba(var(--color-play-level-3), 0.12);
}
/* .play-level.play-level-3:hover,
.play-level.play-level-3:focus {
    background-color: rgba(var(--color-play-level-3),1);
    color: var(--color-neutral-10);
} */

.play-details .play-details-header .header-secondary .header-author {
  font-size: var(--fs-sm);
}

.play-details .play-details-header .header-secondary .header-author a {
  color: var(--color-neutral-90);
  text-decoration: none;
}

.play-details .play-details-header .header-secondary .header-author a:hover,
.play-details .play-details-header .header-secondary .header-author a:focus {
  color: var(--color-neutral-100);
  border-bottom: solid 2px var(--color-brand-primary);
}

.play-details .play-details-header .header-secondary {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  flex-wrap: wrap;
  grid-gap: 0.2rem 0.6rem;
  /* align-items: center; */
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .play-details .play-details-header .header-secondary {
    margin-top: 0.3rem;
  }
}

.play-details .play-details-header .list-tags {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  grid-gap: 0.6rem;
}

.play-tag {
  display: inline-flex;
  line-height: 1.1;
  border-radius: 0.2rem;
  font-size: var(--fs-xs);
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
  background-color: rgba(var(--color-neutral-90-rgb), 0.08);
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  color: var(--color-neutral-60);
  text-decoration: none;
  letter-spacing: 0.01rem;
  transition: all 0.12s ease-in-out;
}

/* .play-tag:hover,
.play-tag:focus {
    background-color: var(--color-neutral-70);
    color: var(--color-neutral-10);
} */

.play-details .play-details-header .header-title {
  margin: 0;
  font-size: var(--fs-md);
  font-weight: var(--fw-bold);
}

.play-details .play-details-header .header-desc {
  margin: 0;
  font-size: var(--fs-xs);
  color: var(--color-neutral-60);
  text-align: right;
}

.play-details .play-details-header .header-title-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 0.6rem;
}

@media screen and (max-width: 768px) {
  .play-details .play-details-header .header-title-tags {
    grid-gap: 0.3rem;
  }
}

.play-details .play-details-header .header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 1rem;
}

.play-details .play-details-body {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

/* .play-details .play-details-body h1 {
    font-size: var(--fs-lg);
    font-weight: var(--fw-bold);
    margin-bottom: .6rem;
} */

/* .play-details .play-details-body h2 {
    font-size: var(--fs-md);
    font-weight: var(--fw-bold);
    margin-bottom: .6rem;
} */

/* .play-details .play-details-body p {
    margin: 1rem 0;
    font-size: var(--fs-rg);
} */

.play-details .play-details-body p a {
  color: blue;
  text-decoration: underline;
}

/* .play-details .play-details-body ul {
    margin: 0;
} */

/* .play-details .play-details-body img {
    max-width: unset;
    height: unset;
} */

.play-details .play-details-body input[type='text'],
.play-details .play-details-body input[type='password'],
.play-details .play-details-body input[type='email'],
.play-details .play-details-body input[type='datetime-local'] {
  border: solid 1px var(--color-neutral-50);
  padding: 0.4rem;
}

.play-details .header-actions .action {
  position: relative;
  width: 24px;
  height: 24px;
  border: 0;
  background: transparent;
}

.play-details .header-actions .action.counted {
  display: flex;
  width: auto;
  height: auto;
  text-decoration: none;
  grid-gap: 0.2rem;
  align-items: center;
}

.play-details .header-actions .action.counted .count-value {
  margin-top: 2px;
  font-size: var(--fs-rg);
  font-weight: var(--fw-semibold);
  color: rgba(var(--color-neutral-90-rgb), 0.4);
}

.play-details .header-actions .action.badged {
  display: flex;
  width: auto;
  height: auto;
  text-decoration: none;
}

.play-details .header-actions .action.badged .badge-count {
  position: relative;
  top: -0.4rem;
  right: 0;
  margin-left: -0.6rem;

  text-decoration: none;
  min-width: 0.6rem;
  min-height: 0.6rem;
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  height: 1.4rem;
  font-size: var(--fs-xxs);
  font-weight: var(--fw-semibold);
  line-height: var(--lh-rg);
  color: var(--color-neutral-90);
  border: solid 1px var(--color-neutral-10);
  border-radius: 1rem;
  background-color: var(--color-brand-primary);
}

.play-details .header-actions .action .badge {
  position: absolute;
  top: -2px;
  right: 0;
  width: 0.6rem;
  height: 0.6rem;
  border: solid 1px var(--color-neutral-10);
  border-radius: 1rem;
  background-color: var(--color-brand-primary);
}

.play-details .header-actions .action .icon {
  fill: rgba(var(--color-neutral-90-rgb), 0.4);
}

.play-details .header-actions .action .icon.active,
.play-details .header-actions .action:hover .icon,
.play-details .header-actions .action:focus .icon {
  fill: rgba(var(--color-neutral-90-rgb), 0.8);
}

.play-details .header-actions .action:hover .count-value,
.play-details .header-actions .action:focus .badge-count {
  color: rgba(var(--color-neutral-90-rgb), 0.8);
}

.play-details .header-actions .action.badged:hover .badge-count {
  background-color: var(--color-neutral-90);
  color: var(--color-neutral-10);
}

.play-details .header-actions .app-header-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 2px var(--color-neutral-40);
  border-radius: 2rem;
  grid-gap: 0.6rem;
  padding: 0.4rem 1rem;
  text-decoration: none;
  font-size: var(--fs-sm);
  width: unset;
  height: unset;
  color: var(--color-neutral-60);
  transition: all 0.12s ease-in-out;
}

.play-details .header-actions .app-header-btn .icon {
  fill: rgba(var(--color-neutral-90-rgb), 0.4);
}

.play-details .header-actions .app-header-btn:hover .icon,
.play-details .header-actions .app-header-btn:focus .icon {
  fill: rgba(var(--color-neutral-90-rgb), 0.9);
}

.play-details .header-actions .app-header-btn:hover,
.play-details .header-actions .app-header-btn:focus {
  border-color: var(--color-brand-primary);
  color: rgba(var(--color-neutral-90-rgb), 0.9);
}

/* Play Comments */
.play-details-comments {
  position: fixed;
  top: 64px;
  right: 0;
  width: 60%;
  max-width: 600px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-10);
  padding: 1rem 0 0 0;
  box-shadow: 0px 0 26px 0px rgba(var(--color-neutral-90-rgb), 0.62);
  -webkit-box-shadow: 0px 0 26px 0px rgba(var(--color-neutral-90-rgb), 0.62);
  -moz-box-shadow: 0px 0 26px 0px rgba(var(--color-neutral-90-rgb), 0.62);
  overflow: hidden;
  z-index: 100;
}

@media screen and (max-width: 768px) {
  .play-details-comments {
    width: 80%;
  }
}

.play-details-comments .comments-header {
  padding: 0 2rem;
  border-bottom: solid 1px var(--color-neutral-30);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.play-details-comments .comments-header .header-action {
  border: 0;
  width: 24px;
  height: 24px;
  background: transparent;
}

.play-details-comments .comments-header .header-action .icon {
  fill: rgba(var(--color-neutral-90-rgb), 0.4);
}

.play-details-comments .comments-header .header-action:hover .icon,
.play-details-comments .comments-header .header-action:focus .icon {
  fill: rgba(var(--color-neutral-90-rgb), 0.8);
}

.play-details-comments .comments-body {
  padding: 1rem 2rem 0 2rem;
  flex-grow: 1;
  overflow-y: auto;
}

.play-details-comments .comments-header .header-title {
  margin: 0 0 0.6rem 0;
  padding: 0;
  font-size: var(--fs-md);
  font-weight: var(--fw-bold);
  color: var(--color-neutral-80);
}
/* Play not found */
.play-not-found,
.error-boundary-fallback {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.play-not-found .play-not-found-image,
.error-boundary-fallback .error-boundary-image {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .play-not-found .play-not-found-image,
  .error-boundary-fallback .error-boundary-image {
    max-width: 50%;
    margin-bottom: 2rem;
  }

  /* Correct sizing for play cards in smaller screens */
  .list-plays {
    padding: 2rem 1.4rem;
  }

  .list-plays a .play-header .play-author {
    font-size: var(--fs-rg);
  }

  .list-plays a .play-header .language {
    height: 1.5rem;
    width: 1.5rem;
  }

  .list-plays a .play-title {
    margin-bottom: 0.6rem;
    font-size: var(--fs-md);
  }
  .list-plays a .play-thumb {
    width: var(--play-thumb-size-w-mobile);
    height: var(--play-thumb-size-h-mobile);
  }
  .list-plays a .play-thumb-img {
    width: var(--play-thumb-size-w-mobile);
    height: var(--play-thumb-size-h-mobile);
  }
}

/* dynamic banner */
.dynamic-banner-container {
  display: flex;
  color: white;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.dynamic-banner-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-weight: bold;
  max-width: 600px;
}

.banner-button {
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 0.947);
  border: none;
  outline: none;
  transition-timing-function: 0.5s;
  transition: all;
  cursor: pointer;
  color: black;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.banner-button:hover {
  background-color: #e6e6e6c3;
  color: black;
}
.right-arrow-icon {
  color: #25a0a7;
}

.search-summary {
  padding: 1.6rem 2rem 0rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.sort-by-plays-wrapper {
  padding: 1.6rem 2rem 0rem 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

#sort-by-plays {
  width: 150px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid grey;
}

.play-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 2px solid #757575;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  width: 300px;
  height: 100%;
  padding: 12px;
}

.play-card-container:hover {
  transform: scale(105%);
  transition: transform 0.2s ease-in-out;
}

.border {
  border: 1px solid rgb(189 183 183);
  margin: 8px;
  /* padding: 20px; */
}
.author {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: flex-start;
}
.card-cover {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.author-name {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 200;
}
.card-header {
  font-size: 18px;
  padding: 10px;
  text-align: center;
}

.img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.card-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
}
.like-btn,
.lng-btn {
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}
.like-btn:hover,
.lng-btn:hover {
  background-color: #0056b3;
}

.like-container {
  display: flex;
  align-items: center; /* Align children vertically */
  justify-content: space-between;
}

.language {
  height: 1.25rem;
  width: 1.25rem;
  background-repeat: no-repeat;
  opacity: 0.2;
}

.play-thumb-container {
  position: relative;
  overflow: hidden;
}

.play-card-thumb-img {
  width: 100%;
  height: 146px;
  object-fit: cover;
  transition: filter 0.3s ease-in-out;
}

.play-card-container:hover .play-card-thumb-img {
  filter: blur(3px) brightness(0.7) saturate(50%);
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.play-card-container:hover .play-icon {
  opacity: 1;
}

.lang-icon {
  filter: grayscale(100%);
}

.play-card-container:hover .lang-icon {
  filter: none;
}
