@import url('https://fonts.googleapis.com/css2?family=Hanalei+Fill&display=swap');

.number-facts {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.digits-fact-container {
  margin-left: 3rem;
  height: 16rem;
}

.fact {
  opacity: 1;
  margin: 2rem 0rem;
  font-weight: 600;
}

.digits-random-facts-btn {
  font-size: 25px;
  width: 8em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  font-family: sans-serif;
  text-transform: capitalize;
  position: relative;
  transition: 0.5s;
  margin: 0.8em;
  font-family: 'Hanalei Fill', cursive;
  z-index: 15;
}

.digits-random-facts-btn::before,
.digits-random-facts-btn::after {
  content: '';
  position: absolute;
  width: 0.6em;
  height: 0.6em;
  background-color: gainsboro;
  border-radius: 50%;
  transition: 0.5s cubic-bezier(0.5, -0.5, 0.25, 1.5);
  top: calc(50% - 0.6em / 2);
}

.digits-random-facts-btn::before {
  left: 0;
  z-index: -8;
}

.digits-random-facts-btn::after {
  right: 0;
  z-index: -9;
}

.digits-random-facts-btn:hover {
  color: black;
  box-shadow: -1px -6px 2px 2px black;
  cursor: pointer;
  z-index: 2;
}

.digits-random-facts-btn:hover::before,
.digits-random-facts-btn:hover::after {
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: dodgerblue;
}

.digits-random-facts-btn:hover::before {
  top: 0;
}

.digits-random-facts-btn:hover::after {
  right: -0.4em;
  filter: brightness(0.8);
}

@media (max-width: 612px) {
  .digits-random-facts-btn {
    margin-top: 9rem;
  }

  .digits-fact-container {
    margin-left: 1rem;
  }
}
