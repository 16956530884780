.profilecard-cover-input::-webkit-file-upload-button,
.profilecard-dp-input::-webkit-file-upload-button {
  visibility: hidden;
}
.profilecard-cover-input::before,
.profilecard-dp-input::before {
  background-image: url('./images/edit.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  content: '';
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 640px) {
  .profilecard-cover-input::before,
  .profilecard-dp-input::before {
    background-size: 15px;
    height: 30px;
    width: 30px;
  }
}
