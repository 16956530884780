$quiz-app-green-color2: rgba(0, 154, 49, 1);
$quiz-app-text-color: rgba(32, 73, 105, 1);
$quiz-app-black-color: #444444;
$quiz-app-black-color2: #333333;
$quiz-app-white-color: #ffffff;
$quiz-app-green-color: rgba(0, 160, 62, 1);

$quiz-app-light-gray: rgba(242, 244, 246, 1);
$quiz-app-light-gray2: rgba(255, 247, 247, 1);
$quiz-app-light-gray3: rgba(255, 247, 255, 1);

$quiz-app-border-color: #ccc;

$quiz-app-transparent-black: rgba(0, 0, 0, 0.1);

$quiz-app-deep-blue: #0a66c2;
$quiz-app-deep-blue2: #16437e;

$quiz-app-light-red: rgba(242, 49, 127, 1);
$quiz-app-light-red2: rgba(246, 0, 60, 1);

$quiz-app-raw-green: green;
$quiz-app-raw-red: red;
$quiz-app-raw-black: black;
