.digits-delight-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 50vw;
  max-width: 800px;
  z-index: 11;
  border-radius: 10px;
  animation: modalApear 0.3s ease-in-out;
}

.digits-delight-modal .close-icon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: 38px;
  padding: 5px;
  filter: brightness(0.1);
  cursor: pointer;
  font-size: 31px;
}

@media screen and (max-width: 768px) {
  .digits-delight-modal .close-icon {
    right: 1rem;
    top: 1rem;
    width: 30px;
  }
}

.digits-delight-modal .content {
  margin: 0 2rem;
  padding: 2rem;
}

.digits-delight-modal .content .text {
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
}

.digits-delight-modal .content p {
  margin: 0.6rem 0 1rem 0;
  text-align: center;
}

.digits-delight-modal .content .guide-image {
  display: block;
  margin: 0 auto;
  max-width: 70%;
  height: auto;
}

.digits-delight-modal .content .button-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 32px;
}

.digits-delight-modal .content .button-section button {
  padding: 0.5rem 2rem;
  font-size: 1rem;
  border-radius: 20px;
  border: none;
  background-color: rgba(81, 91, 212, 1);
  outline: none;
  color: #fff;
  font-weight: bold;
}

.digits-delight-modal .content .button-section button:disabled {
  background-color: rgba(81, 91, 212, 0.5);
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .digits-delight-modal .content .button-section {
    padding: 0;
    margin-top: 10px;
  }
}

@keyframes modalApear {
  0% {
    opacity: 0;
    top: 48%;
  }

  100% {
    opacity: 1;
    top: 50%;
  }
}

@media screen and (max-width: 768px) {
  .digits-delight-modal {
    width: calc(100vw - 20px);
  }
}

.digits-delight-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.digits-delight-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 50vw;
  max-width: 800px;
  z-index: 11;
  border-radius: 10px;
  animation: modalApear 0.3s ease-in-out;
}

.digits-delight-modal .close-icon {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 30px;
  padding: 5px;
  filter: brightness(0.1);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .digits-delight-modal .close-icon {
    right: 1rem;
    top: 1rem;
    width: 30px;
  }
}

.digits-delight-modal .content {
  margin: 0 2rem;
  padding: 2rem;
}

.digits-delight-modal .content .text {
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
}

.digits-delight-modal .content p {
  margin: 0.6rem 0 1rem 0;
  text-align: center;
}

.digits-delight-modal .content .guide-image {
  display: block;
  margin: 0 auto;
  max-width: 70%;
  height: auto;
}

.digits-delight-modal .content .button-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 32px;
}

.digits-delight-modal .content .button-section button {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  will-change: box-shadow, transform;
  background: radial-gradient(100% 100% at 100% 0%, #89e5ff 0%, #5468ff 100%);
  box-shadow:
    0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%),
    inset 0px -3px 0px rgb(58 65 111 / 50%);
  padding: 0 32px;
  border-radius: 6px;
  color: #fff;
  height: 48px;
  font-size: 18px;
  text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
  transition:
    box-shadow 0.15s ease,
    transform 0.15s ease;
}

.digits-delight-modal .content .button-section button :hover {
  box-shadow:
    0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%),
    inset 0px -3px 0px #3c4fe0;
  transform: translateY(-2px);
}

.digits-delight-modal .content .button-section button:active {
  box-shadow: inset 0px 3px 7px #3c4fe0;
  transform: translateY(2px);
}

.digits-delight-modal .content .button-section button:disabled {
  background-color: rgba(81, 91, 212, 0.5);
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .digits-delight-modal .content .button-section {
    padding: 0;
    margin-top: 10px;
  }
}

@keyframes modalApear {
  0% {
    opacity: 0;
    top: 48%;
  }

  100% {
    opacity: 1;
    top: 50%;
  }
}

@media screen and (max-width: 768px) {
  .digits-delight-modal {
    width: calc(100vw - 20px);
  }
}
