/* enter stlyes here */
.card-container {
  display: grid;
  grid-gap: 24px;
  padding: 84px 16px;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.card {
  cursor: pointer;
  background-color: #fff !important;
  height: 400px;
  width: 200px;
}

.card:hover {
  transition: transform 0.2s;
  transform: scale(1.1);
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 4px 16px;
}

.author {
  font-weight: 600;
  font-size: 12px;
}

.published-at {
  font-size: 10px;
}

.header-panel {
  position: fixed;
  width: 100%;
  background-color: lightskyblue;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  height: 64px;
  padding: 16px;
  z-index: 1;
  align-items: center;
}

.header-panel-inputs {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.menu-form-control {
  width: 200px;
}

.category-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.app-title {
  font-size: 24px;
  font-weight: 500;
}

@media (min-width: 480px) {
  .card-container {
    grid-template-columns: repeat(2, 200px);
    padding: 136px 16px;
  }
  .header-panel {
    display: flex;
    flex-direction: row;
    height: 120px;
  }
  .header-panel-inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .menu-form-control {
    width: 120px;
  }
}

@media (min-width: 780px) {
  .card-container {
    grid-template-columns: repeat(3, 200px);
    padding: 136px 16px;
  }
}

@media (min-width: 960px) {
  .card-container {
    grid-template-columns: repeat(4, 200px);
    padding: 136px 16px;
  }
}

@media (min-width: 1024px) {
  .card-container {
    grid-template-columns: repeat(5, 200px);
    padding: 84px 16px;
  }
  .header-panel {
    display: flex;
    flex-direction: row;
    height: 64px;
  }
  .header-panel-inputs {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  .menu-form-control {
    width: 200px;
  }
}
